<template>
    <div>
        <el-container style="width: 100%; border: 0px solid #eee; background-color: #efefef">
            <el-header height="4rem" style="background-color: #dca571; position: fixed; width: 100%; z-index: 3" >
                <el-row>
                    <el-col :span="12" :offset="6">
                        <div style="height: 12px"></div>
                        <div class="header"><span style="display: inline; color: #fff;">{{questionnaire.title}}</span></div>
                    </el-col>
                    <el-col :span="6">
                        <el-dropdown style="color: #fff; margin-top: 1.3rem" class="dropDown">
                            <span class="el-dropdown-link">{{"你好，"}}{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item divided @click.native="toPersonalCenter">个人信息</el-dropdown-item>
                                <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>  
                </el-row>
            </el-header>
            <el-main style="background-color: #efefef; margin-right: 0; margin-left: 0; margin-top: 5rem; padding: 0">
                <el-row :gutter="10">
                    <el-col :xs="{span:22, offset:1}" :sm="{span:18, offset:3}">
                        <div direction="vertical"
                            id="cardContainer"
                            style="margin: 4rem auto 7rem; background-color: #fff;">
                            <el-card v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="groupQuestion.group">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="{span:16, offset:4}" >
                                        <el-form
                                            label-position="top"
                                            label-width="550px"
                                            style="font-weight: 600; text-align: left; margin: 3rem auto 3rem;">
                                            <div style="color: #555555; font-size: 20px; font-weight: 600; width: 95%; text-align: center; background-color: #fff; padding-left: 3.6%; margin: 1rem">
                                                {{groupQuestion.title}}
                                            </div>
                                            <div class="questionBox" id="questionBox" v-for="(message, qIndex) in groupQuestion.messages" :key="message.content" >                        
                                                <div class="formBox">
                                                    <span >  
                                                        {{message.id + '. ' + message.content}}
                                                        <el-button icon="el-icon-video-play" type="text" @click="startPlay(group, qIndex)"></el-button>
                                                        <br>
                                                        <el-radio-group v-model="userAnswer[group][qIndex].choiceAnswer">
                                                            <el-radio v-for="(option, optionIndex) in groupQuestion.messages[qIndex].options" :key="optionIndex" :label="optionIndex">{{option}}</el-radio>
                                                        </el-radio-group>
                                                    </span>
                                                    <audio ref="audio" :src="audioSrc[group][qIndex]" style="display: none">
                                                    </audio>
                                                </div>
                                            </div>
                                        </el-form>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </div>
                <div v-loading="loading" style="margin-top: 3rem; margin-bottom: 3rem">
                    <el-button @click="submitData" type="primary" icon="el-icon-document">確 定</el-button>
                </div>
            </el-col>
        </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            audioSrc: [],
            height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            width: document.documentElement.clientWidth,
            tableData: [],
            userAnswer: [],
            questionnaire: {},
            loading: false,
            name: '',
            username: '',
            patientName: '',
            patientID: '',
            labelPosition: "left",
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        startPlay(groupIndex, qIndex) {
            this.$refs.audio[2*groupIndex + qIndex].play()
        },
        onResize() {
            this.width = document.documentElement.clientWidth
            this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        },
        toHome() {
            this.$router.push({path: '/questionnaire'})
        },
        async submitData() {
                this.loading = true
                console.log("gethere")
                try {
                    let res = await axios({
                        method: 'post',
                        url: window.serverURL + 'answer',
                        data: {
                            Name: this.name,
                            UserName: this.username,
                            SurveyName: this.questionnaire.title,
                            SurveyType: this.questionnaire.type,
                            SurveyData: this.userAnswer
                        },
                    })
                    this.$message({
                        message: '问卷提交成功！',
                        type: 'success'
                    })
                        var value = Object.values(res.data.data)
                        var key = Object.keys(res.data.data)
                        for (let i = 0; i < key.length; i++) {
                            this.$set(this.$store.state.pdfdata, key[i], value[i])
                        }
                        localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                        this.$router.push({path:'/report/' + this.questionnaire.type}) 
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '问卷提交失败，请再次尝试提交。',
                        type: 'error'
                    })
                }
            },
    },
    async created() {
         if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
        this.questionnaire.type = this.$route.path.slice(15)
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        try {
            var res = await axios.get(window.serverURL + "question?name=" + this.questionnaire.type)
                var temp = res.data.data
                for (let i = 0; i < temp.length; i++) {
                    this.questionnaire = {}
                    this.$set(this.questionnaire,"id",temp[0].id)
                    this.$set(this.questionnaire,"type",temp[0].type)
                    this.$set(this.questionnaire,"title",temp[0].title)
                    this.$set(this.questionnaire,"mentor",temp[0].mentor)
                    this.$set(this.questionnaire,"mentorJudge",temp[0].mentorJudge)
                    this.questionnaire.groupQuestions = []
                    var l = temp.length
                    var grouplength = temp[l-1].group + 1
                    for (let j = 0; j < grouplength; j++ ) {
                        var groupQuestion = {}
                        groupQuestion.group = j
                        for (let t = 0; t < temp.length; t++) {
                            var g = temp[t].group
                            if (groupQuestion.group == g) {
                                groupQuestion.title = temp[t].group_title
                            }
                        }
                        this.questionnaire.groupQuestions.push(groupQuestion)
                        this.questionnaire.groupQuestions[j].messages = []
                        for (let k = 0; k < temp.length; k++) {
                            if (temp[k].group == this.questionnaire.groupQuestions[j].group) {
                                var message = {}
                                message.id = temp[k].question_id
                                message.type = temp[k].question_type
                                message.content = temp[k].content
                                if (temp[k].tableheader.length != 0) {
                                    message.headers = JSON.parse(temp[k].tableheader)
                                }
                                message.supplement = temp[k].supplement
                                if (temp[k].options.length != 0) {
                                    message.options = JSON.parse(temp[k].options)
                                }
                                this.questionnaire.groupQuestions[j].messages.push(message)
                            }
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                this.loading = false
                this.$message({
                    message: '获取量表数据失败，请刷新重试。',
                    type: 'error'
                })
            }
        localStorage.setItem('questionnaire', JSON.stringify(this.questionnaire))
        for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
            this.userAnswer.push([])
            this.audioSrc.push([])
            for (let j = 0; j < this.questionnaire.groupQuestions[i].messages.length; j++) {
                this.userAnswer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer: [], tableData: [], time:"", date:"", supplement: ""})
                let group = i+1
                let qIndex = j+1
                let index = 1
                if (this.questionnaire.type == "DST-2") {
                    index = 2
                }
                this.audioSrc[i].push("https://git.bjbigiq.com/zxx/janpanesescaleimage/-/raw/master/DST%20audio/"+index+"."+group+"."+qIndex+".mp3")
            }
        }
    },
}
</script>

<style scoped>
 @media screen and (max-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 10px;
    }
}

@media screen and (min-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 30px;
    }
}
.demo-form-inline >>> .el-form-item__label {
    padding: 0 5px 0 0;
    font-size: 20px;
}
.formLeft >>> .el-form-item__label {
    font-size: 20px;
}
.formLeft >>> .el-form-item {
    margin-right: 30px;
}
.radio >>> .el-radio__label {
    font-size: 18px;
}
</style>