import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import Init from '../components/Init.vue'
import SurveyList from '../components/SurveyList.vue'
import commonSurvey from '../components/commonSurvey.vue'
import Doctor from "../components/personalCenter/Doctor.vue"
import User from "../components/personalCenter/User.vue";
import PJManage from "../components/PJManage.vue";
import projectList from "../components/projectList.vue";
import project from "../components/project.vue";
import ScaleList from "../components/ScaleList.vue";
import patientList from "../components/patientList.vue"
import patient from "../components/patient.vue"
import register from "../components/register.vue"
import report from "../components/report.vue"
import DST from "../components/DST.vue"

Vue.use(Router)

export default new Router ({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: "login"
        }, {
            path: '/init',
            component: Init
        }, {
            path: '/register',
            component: register
        }, {
            path: '/login',
            component: Login
        },  {
            path: '/questionnaire/DST-1',
            // name: dst1,
            component: DST
        }, {
            path: '/questionnaire/DST-2',
            // name: dst2,
            component: DST
        },  {
            path: '/questionnaire/:type',
            component: commonSurvey
        }, 
        {
            path: '/report/:type',
            component: report
        }, 
        {
            path: "/questionnaire",
            component: SurveyList,
        }, {
            path: '/personalCenter',
            component: User
        },{
            path: '/home',
            redirect: 'home/scale'
        }, {
            path: '/home',
            component: PJManage,
            children: [
                {
                    path: "scale",
                    component: ScaleList,
                }, {
                    path: 'project',
                    component: projectList,
                }, {
                    path: 'project/:id',
                    component: project,
                }, {
                    path: 'patient',
                    component: patientList,
                }, {
                    path: 'patient/:id',
                    component: patient,
                }, {
                    path: '/doctorPersonalCenter',
                    component: Doctor
                }
            ]
        },
    ]
})
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace (to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}
