<template>
    <div>
        <el-container style="background-color: #efefef">
            <el-main style="margin: 0px; padding: 0px; width:100%" :style="mainStyle">
                <div>
                   <el-card style="width: 98%; align-items: center; margin-top: 9rem; margin-left: auto; margin-right: auto">
                        <el-table
                            :data="questionnaireLists"
                            border
                            stripe
                            style="margin: 4rem auto 7rem; align-items: center; width: 98%;"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            @sort-change='tableSortChange'
                            :default-sort = "{prop: 'id', order:'descending'}">
                            <el-table-column prop="id" label="量表编号" sortable="custom" min-width="45"></el-table-column>
                            <el-table-column prop="title" label="量表名称" min-width="80"></el-table-column>
                            <el-table-column prop="type" label="量表简称" min-width="60"></el-table-column>
                            <el-table-column prop="created_at" label="创建时间" min-width="60"></el-table-column>
                            <el-table-column label="操作" min-width="68">
                                <template slot-scope="scope">
                                    <el-button
                                        @click.native.prevent="toTest(scope.$index)"
                                        type="text"
                                        size="small">
                                            查看
                                    </el-button>
                                </template>
                                </el-table-column>
                        </el-table>
                        <el-pagination
                            style="margin:auto"
                            class="smallPagination"
                            small
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="totalCount">
                        </el-pagination>
                        <el-pagination
                            style="margin:auto"
                            class="Pagination"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount">
                        </el-pagination>
                    </el-card>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                questionnaireLists: [],
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                input: '',
                username:'',
                pageSize: 10,
                pageSizes:[5,10,15,20],
                currentPage: 1,
                totalCount: 0,//questionnaireLists数组的长度，记录数
                order: "",
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            handleSizeChange(val) {//每次切换页面大小时，此方法响应，改变页面大小
                this.pageSize = val;
                this.currentPage = 1;//改变页面大小后，跳转回第一页
                this.searchAllScale()
            },
            handleCurrentChange(val) {//每次切换当前页时，此方法响应，改变当前页
                this.currentPage = val;
                this.searchAllScale()
            },
            toTest(index) {
                this.$router.push({path:'/questionnaire/' + this.questionnaireLists[index].type})
                // for (let i = 0; i < this.questionnaires.length; i++) {
                //     if (this.questionnaires[i].type === this.questionnaireLists[index].type) {
                //         let questionnaire = this.questionnaires[i]
                //         // axios.post(window.serverURL + 'questionnaire', {ID: questionnaire.id, QuestionnaireType: questionnaire.type, QuestionnaireTitle:questionnaire.title, Mentor: questionnaire.mentor, MentorJudge: questionnaire.mentorJudge})
                //         //     .then(response => {
                //         //         this.$message({
                //         //             message: '问卷数据提交成功！',
                //         //             type: 'success'
                //         //         })
                //         //         this.loading = false
                //         //     })
                //         //     .catch(error => {
                //         //         this.loading = false
                //         //         this.$message({
                //         //             message: '问卷数据提交失败，请再次尝试提交。',
                //         //             type: 'error'
                //         //         })
                //         //     })
                //         for (let j = 0; j < questionnaire.groupQuestions.length; j++) {//提交问题
                //             for(let k = 0; k < questionnaire.groupQuestions[j].messages.length; k++){
                //                 let optionString = JSON.stringify(questionnaire.groupQuestions[j].messages[k].options)
                //                 let headersString = JSON.stringify(questionnaire.groupQuestions[j].messages[k].headers)
                //                 axios.post(window.serverURL + 'question', {
                //                     QuestionnaireID: questionnaire.id,
                //                     Group: questionnaire.groupQuestions[j].group,
                //                     GroupTitle: questionnaire.groupQuestions[j].title,
                //                     QuestionID: questionnaire.groupQuestions[j].messages[k].id,
                //                     QuestionType: questionnaire.groupQuestions[j].messages[k].type,
                //                     Content: questionnaire.groupQuestions[j].messages[k].content,
                //                     Supplement: questionnaire.groupQuestions[j].messages[k].supplement,
                //                     TableHeader: headersString,
                //                     Options: optionString
                //                 })
                //                     .then(response => {
                //                         this.$message({
                //                             message: '问题数据提交成功！',
                //                             type: 'success'
                //                         })
                //                         this.loading = false
                //                     })
                //                     .catch(error => {
                //                         this.loading = false
                //                         this.$message({
                //                             message: '问题数据提交失败，请再次尝试提交。',
                //                             type: 'error'
                //                         })
                //                     })
                //             }
                //         }
                //     }
                // }
            },
            toPersonalCenter() {
                this.$router.push({path:'/doctorPersonalCenter'})
            },
            logout() {
                this.$store.commit('logOut')
                this.$router.push('/login')
            },
            tableSortChange(column) {
                this.orderProperty = column.prop
                if (column.order === "descending") {
                    this.order = "descending"
                }else{
                    this.order = ""
                }
                this.searchAllScale()
            },
            async searchAllScale() {//获取所有量表，需要排序、分页
                try {
                    let res = await axios.get(window.serverURL + "project/scale",{
                        params: {
                            page: this.currentPage,
                            pageSize: this.pageSize,
                            descend: this.order,
                        }
                    })
                    this.questionnaireLists = res.data.data.questionnaires
                    for (let i = 0; i < this.questionnaireLists.length; i ++) {
                        this.questionnaireLists[i].created_at = this.questionnaireLists[i].CreatedAt.substring(0, 10).replace("T", " ")
                    }
                    this.totalCount = res.data.data.totalPage
                    this.loading = false
                } catch (e) {
                    this.$message({
                        message: '获取量表信息失败，请刷新重试',
                        type: 'error'
                    });
                }
            }
        },
        created() {
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify/doctor')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.searchAllScale()
            this.username = localStorage.getItem('userName')
        },
    }
</script>

<style scoped>
    .box-card {
        cursor: pointer;
        transition: all 0.6s;
        overflow: visible;
    }
    .box-card:hover {
        transform: scale(1.05);
    }
    .el-main /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    #app {
        background-color: #efefef;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        color: #2c3e50;
    }
    body {
        margin: 0;
        padding: 0;
    }
    .header{
        display: inline-block;
    }
</style>
