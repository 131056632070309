<template>
    <div id=" Init" style="background-color: #ffffff">
        <div style="height: 5rem"></div>
        <el-container>
            <el-main>
                <el-row :gutter="10">
                    <el-col :sm="{span:16, offset:4}" :md="{span:12, offset:10}">
                        <el-card shadow="hover" style="height: 32rem; margin: auto;" :body-style="{padding: '2rem'}" class="registerbox">
                            <el-form :model="user" :rules="rules" ref="user">
                                <div style="height: 0.5rem"></div>
                                <div class="title" style="height: 3rem; font-weight: bold;" >{{"用户注册"}}</div>
                                <div style="height: 0.2rem"></div>
                                <el-form-item prop="name">
                                    <el-input v-model="user.name" placeholder="请输入您的姓名"></el-input>
                                </el-form-item>
                                <el-form-item prop="userName">
                                    <el-input v-model="user.userName" placeholder="请输入账户名" clearable></el-input>
                                </el-form-item>
                                <el-form-item prop="phone">
                                    <el-input v-model="user.phone" placeholder="请输入手机号"></el-input>
                                </el-form-item>
                                <el-form-item prop="passWord">
                                    <el-input v-model="user.passWord" show-password placeholder="请输入密码"></el-input>
                                </el-form-item>
                                <el-form-item prop="identity">
                                    <el-radio-group v-model="user.identity">
                                        <el-radio label="1">用户</el-radio>
                                        <el-radio label="2">医生</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('user')">注册</el-button>
                                </el-form-item>
                                <div style="height: 0.5rem"></div>
                                <div class="notes">{{"注：服务器不会明文储存账户信息，因此请妥善保存您的账户密码。"}}</div>
                            </el-form>
                        </el-card>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import axios from 'axios'
    import qs from 'qs'
    export default {
        data() {
              var vPhone = (rule,value,callback) => {
                let reg = /^1(3|4|5|6|7|8|9)\d{9}$/
                this.PhoneUnique = true
                if (!value){
                    callback(new Error('手机号不能为空'));
                } else if (!reg.test(value)){
                    callback(new Error('手机号格式错误'));
                } else {
                    axios.post(window.serverURL + 'uniqueJudge', {
                        phone: value,
                        role: this.user.identity
                    }).then((res)=>{
                        if (this.user.identity == 1) {
                            if (res.data.data.PhoneUnique != "1"){
                                this.PhoneUnique = false
                                callback()
                            } else {
                                callback()
                            }
                        } else if (this.user.identity == 2) {
                            if (res.data.data.PhoneUnique != "2"){
                                this.PhoneUnique = false
                                callback(new Error('此手机号已注册，请输入其他手机号'));
                            } else {
                                callback()
                            }
                        }
                    })
                }
            }
            var vUsername = (rule,value,callback) => {
                if (!value){
                    callback(new Error('用户名不能为空'));
                } else if (value.length < 2 || value.length > 10 ){
                    callback(new Error('长度在 2 到 10 个字符'));
                } else {
                    axios.post(window.serverURL + 'uniqueJudge', {
                        username: value,
                    }).then(function(res){
                        if (res.data.data.UserNameUnique != true){
                            callback(new Error('此用户名已被注册，请输入其他用户名'));
                        }else {
                            callback()
                        }
                    })
                }
            }
            return {
                PhoneUnique: true,
                user: {
                    userName: '',
                    name: '',
                    passWord: '',
                    identity: '',
                    phone: '',
                },
                rules: {
                    userName: [
                        { validator: vUsername, required: true, trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur'}
                    ],
                    phone: [
                        { validator: vPhone, required: true, trigger: 'blur' }
                    ]
                },
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.init()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async init() {
                try {
                    await axios.post(window.serverURL + 'init', qs.stringify({
                        username: this.user.userName,
                        name: this.user.name,
                        password: this.user.passWord,
                        role: this.user.identity,
                        phone: this.user.phone
                    }))
                    if (this.user.identity == "1") {
                        this.firstLogin(this.PhoneUnique)
                    } else if (this.user.identity == "2") {
                        this.firstLogin(true)
                    }
                } catch (error) {
                    console.log(error);
                    this.$message({
                        message: '注册信息提交失败，请再次尝试提交。',
                        type: 'error'
                    })
                }
            },
            async firstLogin(PhoneUnique) {
                try {
                    var res = await axios.post(window.serverURL + "account/login", qs.stringify({
                        username: this.user.userName,
                        password: this.user.passWord,
                    }))
                    localStorage.setItem('role', res.data.data.Role)
                    localStorage.setItem('username', this.user.userName)
                    this.$store.commit('afterLogin', this.user.userName, res.data.data.role)
                    this.loading = false
                    this.$store.commit('setToken', this.getCookie('token'))
                    if (localStorage.getItem("role") == 1) {
                        this.$router.push('/personalCenter')
                        if (PhoneUnique == false){
                            this.$alert('用户名和密码已更新，其他信息系统已录入，若不正确请自行修改。', '个人信息确认', {
                                confirmButtonText: '确定',
                        });
                        }
                    } else if (localStorage.getItem("role") == 2) {
                        this.$router.push('/home/scale')
                    }
                } catch (err) {
                    this.loading = false
                    this.$message.error('账户或密码错误')
                }
            },
            getCookie(cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for(var i=0; i<ca.length; i++){
                    var c = ca[i].trim();
                    if (c.indexOf(name)==0) return c.substring(name.length,c.length);
                }
                return "";
            }
        },
        name: "Init",
    }
</script>

<style scoped>
    .title {
        color: #222222;
        font-weight: 600;
    }
    .notes {
        font-size: 10px;
    }
    @media screen and (max-width: 768px) {
   .registerbox{
        width: 18rem;
    }
    }
    @media screen and (min-width: 768px) {
    .registerbox{
        width: 22rem;
    }
    }
</style>
