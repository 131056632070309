<template>
    <div style="background-color: #ffffff; width: 100%; margin: auto">
        <el-row>
            <el-col :xs="{span:22, offset:1}" :sm="{span:18, offset: 3}" :md="{span:16, offset:4}">
                <div id="Report" style="width: 80%; margin:auto">
                    <div>
                        <div style="height: 5rem; font-size: 25px; font-weight: 700; margin-top: 3rem; margin-left:auto; margin-right:auto; text-align: center; ">{{questionnaire.title}}{{"分析报告"}}</div>
                        <el-row style="font-weight: 400; line-height: 1.5;">
                            <el-col :span="10" style="text-align: left; font-family: Times New Roman">{{"报告编号: "}}{{pdfdata.basicreport.ID}}</el-col>
                            <el-col :span="14" style="text-align: right; font-family: Times New Roman">{{"创建时间: "}}{{pdfdata.basicreport.CreatedAt}}</el-col>
                        </el-row>
                        <el-divider style="margin-top:2rem"></el-divider>
                        <el-descriptions title="基本信息"  :column="3">
                            <el-descriptions-item label="姓名">{{pdfdata.basicreport.Name}}</el-descriptions-item>
                            <el-descriptions-item label="性别">{{pdfdata.basicreport.Sex}}</el-descriptions-item>
                            <el-descriptions-item label="年龄">{{pdfdata.basicreport.Age}}</el-descriptions-item>
                            <el-descriptions-item label="病例号">{{pdfdata.basicreport.CaseID}}</el-descriptions-item>
                            <el-descriptions-item label="手机号">{{pdfdata.basicreport.Phone}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div style="margin: auto">
                        <el-divider></el-divider>
                        <el-descriptions title="分析结果" direction="vertical" :column="5" border v-if="pdfdata.SCLResult">
                            <el-descriptions-item  label="总分">{{pdfdata.SCLResult[0]}}</el-descriptions-item>
                            <el-descriptions-item  label="总症状指数">{{pdfdata.SCLResult[1]}}</el-descriptions-item>
                            <el-descriptions-item  label="阳性项目数">{{pdfdata.SCLResult[2]}}</el-descriptions-item>
                            <el-descriptions-item  label="阳性症状均分">{{pdfdata.SCLResult[3]}}</el-descriptions-item>
                            <el-descriptions-item  label="建议">{{pdfdata.finalsuggestion}}</el-descriptions-item>
                            <el-descriptions-item  label="分析解释">{{pdfdata.resultExplain}}</el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions title="分析结果" v-else>
                            <el-descriptions-item  label="总分" v-if="pdfdata.basicreport.TotalScore">{{pdfdata.basicreport.TotalScore}}</el-descriptions-item>
                            <el-descriptions-item  v-if="pdfdata.finalsuggestion" label="建议">{{pdfdata.finalsuggestion}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-show="pdfdata.TableData" style="padding:20px 0px; margin:auto; " >
                        <el-table
                            :data="pdfdata.TableData"
                            border
                            stripe
                            :row-style="{height:0 +'px'}"
                            :header-cell-style="{'text-align':'center','padding':'10px'}"
                            :cell-style="{'text-align':'center','padding':'10px 0px'}"
                            class="reslutTable"
                            style="overflow: visible; display: inline-block;"
                            >
                            <el-table-column
                                v-for="item in pdfdata.TableHeader"
                                :key="item.prop"
                                :property="item.prop"
                                :label="item.propName"
                            >
                                <template slot-scope="scope" >
                                    <span>{{scope.row[scope.column.property]}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="margin: auto;text-align: left; " >
                        <div style="font-size: 20px; font-weight: 700; line-height: 2" class="el-icon-paperclip">{{"附录：问卷详情"}}</div>
                        <div v-for="(groupQuestion,group) in questionnaire.groupQuestions" :key="group">
                            <div class="surveyBox" label-position="top" label-width="550px" style="font-weight: 400; text-align: left; line-height: 1.5;">
                                <div style="color: #555555; font-size: 16px; font-weight: 600; background-color: #fff; margin-top:1.5rem;">
                                    {{groupQuestion.title}}
                                </div>
                                <div class="questionBox" id="questionBox" v-for="(message, qIndex) in groupQuestion.messages" :key="qIndex"  style="font-size: 14px;">
                                    <div style="text-align: left; font-weight: 400; line-height: 2;" v-if="questionnaire.title ==='快感缺失评定量表'">
                                        <div v-if="message.id === 0">{{message.content}}</div>
                                        <div v-else>{{message.id}}{{"."}}{{message.content}}</div>
                                        <div style="margin-left:1rem;">{{pdfdata.AnswerList[group][qIndex]}}</div>
                                    </div>
                                    <div style="text-align: left; font-weight: 400; line-height: 2;" v-else>
                                        <div>{{message.id}}{{"."}}{{message.content}}</div>
                                        <div style="margin-left:1rem;">{{pdfdata.AnswerList[group][qIndex]}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-button @click="test" type="primary" style="margin-bottom: 4rem">下载报告</el-button>
    </div>
</template>

<script>
    import axios from 'axios'
    import html2pdf from 'html2pdf.js'
    export default {
        data() {
            return {
                questionnaire: {},
                message: {},
                groupQuestions: [],
                groupQuestion: {},
                pdfdata: {},
                suggestionJudge:false,
            }
        },
        methods: {
            test() {
                var width = document.body.clientWidth
                var fileName = this.questionnaire.title + '分析报告.pdf'
                var element = document.getElementById('Report');
                var opt = {
                    margin:       [0.5, 0, 0.5, 0],
                    filename: fileName,
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2, scrollX: 0, scrollY: 0, windowWidth: width},
                    jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
                    pagebreak:    { mode: [ 'avoid-all' , 'css' ,  'legacy' ]}
                };
                html2pdf().set(opt).from(element).save();
            }
        },
        
    async created() {
        this.pdfdata = JSON.parse(localStorage.getItem('pdfdata'))
        this.pdfdata.basicreport.CreatedAt = this.pdfdata.basicreport.CreatedAt.substring(0, 10).replace("T", " ")
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        if (this.pdfdata.finalsuggestion) {
            this.suggestionJudge = true
        }
        if (this.$route.path !== '/login') {
            this.loading = true
            axios.get(window.serverURL + 'verify')
                .then(response => {
                    if (response.status === 200) {
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$store.commit('needLogin')
                })
                .catch(error => {
                    this.loading = false
                    this.$router.push('/login')
                })
        }
        this.questionnaire.type = this.$route.params.type
        try {
            if (this.questionnaire.type == "SAS&SDS") {
                var res = await axios.get(window.serverURL + "question?name=" + "SAS%26SDS")
            } else {
                res = await axios.get(window.serverURL + "question?name=" + this.questionnaire.type)
            }
            var temp = res.data.data
            for (let i = 0; i < temp.length; i ++ ) {
                this.questionnaire = {}
                this.$set(this.questionnaire,"id",temp[0].id)
                this.$set(this.questionnaire,"type",temp[0].type)
                this.$set(this.questionnaire,"title",temp[0].title)
                this.$set(this.questionnaire,"mentor",temp[0].mentor)
                this.$set(this.questionnaire,"mentorJudge",temp[0].mentorJudge)
                this.questionnaire.groupQuestions = []
                var l = temp.length
                var grouplength = temp[l-1].group + 1
                for (let j = 0; j < grouplength; j ++ ) {
                    var groupQuestion = {}
                    groupQuestion.group = j
                    for (let t = 0; t < temp.length; t ++) {
                        var g = temp[t].group
                        if (groupQuestion.group == g) {
                            groupQuestion.title = temp[t].group_title
                        }
                    }
                    this.questionnaire.groupQuestions.push(groupQuestion)
                    this.questionnaire.groupQuestions[j].messages = []
                    for (let k = 0; k < temp.length; k++) {
                        if (temp[k].group == this.questionnaire.groupQuestions[j].group) {
                            var message = {}
                            message.id = temp[k].question_id
                            message.type = temp[k].question_type
                            message.content = temp[k].content
                            if (temp[k].tableheader.length != 0) {
                                message.headers = JSON.parse(temp[k].tableheader)
                            }
                            message.supplement = temp[k].supplement
                            if (temp[k].options.length != 0) {
                                message.options = JSON.parse(temp[k].options)
                            }
                            this.questionnaire.groupQuestions[j].messages.push(message)
                        }
                    }
                }
            }
            this.loading = false
        } catch (e) {
            this.loading = false
            this.$message({
                message: '获取量表数据失败，请刷新重试。',
                type: 'error'
            })
        }
        if (this.questionnaire.mentorJudge === true) {
            this.dialogVisible = true
        }
    }
    }
</script>

<style scoped>
    .title {
        color: #222222;
        font-weight: 600;
    }
    .notes {
        font-size: 10px;
    }
    .el-descriptions >>>.el-descriptions__title {
        font-size: 20px;
        font-weight: 700;
    }
    .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 24px;
        margin-left: 0px;
    }
    @media screen and (max-width: 641px) {
    .reslutTable{
        width: 260px;
    }
    }
    @media screen and (min-width: 767px) {
    .reslutTable{
        width: 450px;
    }
    }
    @media screen and (min-width: 1025px) {
    .reslutTable{
        width: 600px;
    }
    }
</style>