<template>
    <div>
        <el-main :style="mainStyle" style="padding: 0; margin: 0; background-color: #efefef; color: rgba(0,0,0,0.98);">
            <div style="font-size: 25px; float: left; margin-left: 2rem; margin-top: 6rem">
                {{"个人信息"}}
            </div>
            <el-card style="width: 98%; align-items: center; margin-top: 9rem; margin-left: auto; margin-right: auto">
                <el-row>
                    <el-col :xs="{span:24}" :sm="{span:12, offset:6}" :md="{span:8, offset:8}">
                        <div id="cardContainer" style="margin: 4rem auto 7rem; background-color: #fff;">
                            <el-form :label-position="labelPosition" label-width="80px" :model="doctor" ref="doctor" :rules="rules1">
                                <el-form-item label="用户名" prop="username">
                                    <el-input v-model="doctor.username"></el-input>
                                </el-form-item>
                                <el-form-item label="姓名" prop="name">
                                    <el-input v-model="doctor.name"></el-input>
                                </el-form-item>
                                <el-form-item label="科室">
                                    <el-input v-model="doctor.department"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号">
                                    <el-row>
                                        <el-col :xs="19" :sm="17" :md="21">
                                            <el-input v-model="doctor.phone" disabled></el-input>
                                        </el-col>
                                        <el-col :xs="{span:3, offset:1}" :sm="{span:4, offset:1}" :md="{span:2, offset:1}">
                                            <el-button type="primary"  @click="dialogFormVisible = true" icon="el-icon-edit" circle></el-button>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="邮箱" prop="email" 
                                    :rules="[
                                        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
                                    <el-input v-model="doctor.email"></el-input>
                                </el-form-item>
                            </el-form>
                            <el-row style="line-height: 20px; margin-top:1rem; margin-bottom:3rem; margin-left: auto; margin-right: auto;">
                                <el-col style="color: rgba(0,0,0,0.98)" :span="12">
                                    <el-button type="primary" @click="submitForm('doctor')" style="float: left;">保存</el-button>
                                </el-col>
                                <el-col style="color: rgba(0,0,0,0.98);" :span="12">
                                    <el-button @click="jump" style="float: right;">返回</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
                <el-dialog title="修改手机号" :visible.sync="dialogFormVisible" style="margin:auto" width="100%" class="dialog">
                    <el-form :model="newDoctor" :label-position="labelPosition" ref="newDoctor" :rules="rules" style="width: 80%; margin: auto">
                        <el-form-item label="手机号" label-width="80px" prop="phone">
                            <el-input v-model="newDoctor.phone" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitPhone('newDoctor')">确 定</el-button>
                    </div>
                </el-dialog>
            </el-card>
        </el-main>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            var vPhone = (rule,value,callback) => {
                let reg = /^1(3|4|5|6|7|8|9)\d{9}$/
                var _this = this;
                if (!value){
                    callback(new Error('手机号不能为空'));
                } else if (!reg.test(value)){
                    callback(new Error('手机号格式错误'));
                } else if (value == _this.rawData.phone){
                    callback(new Error('手机号未更改'))
                } else {
                    axios.post(window.serverURL + 'uniqueJudge', {
                        phone: value,
                        role: "2"
                    }).then(function(res){
                        if (res.data.data.PhoneUnique != "2"){
                            callback(new Error('此手机号已注册，请输入其他手机号'));
                        }else {
                            callback()
                        }
                    })
                }
            }
            var vUsername = (rule,value,callback) => {
                this.UserNameUnique = true
                if (value != this.rawData.username){
                    if (!value){
                        callback(new Error('用户名不能为空'));
                    } else if (value.length < 2 || value.length > 10 ){
                        callback(new Error('长度在 2 到 10 个字符'));
                    } else {
                        axios.post(window.serverURL + 'uniqueJudge', {
                            username: value,
                        }).then(function(res){
                            if (res.data.data.UserNameUnique != true){
                                callback(new Error('此用户名已被注册，请输入其他用户名'));
                            }else {
                                callback()
                            }
                        })
                    }
                } else {
                    callback()
                }
            }
            return {
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                loading:false,
                labelPosition: 'right',
                doctor: {
                    email: '',
                    phone: '',
                    name: '',
                    username: '',
                    },
                rawData:{},//从数据库获得的原始数据
                diffData:{},
                tempPhone:'',
                newDoctor:{
                    phone:''
                },
                dialogFormVisible: false,
                rules: {
                    phone: [
                        { validator: vPhone, required: true, trigger: 'blur' }
                    ],
                },
                rules1: {
                    username: [
                        { validator: vUsername, required: true, trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            jump() {
                this.$router.push({path:'/home'})
            },
            async updateUser(){
                let ifChange = false
                for (var k in this.rawData) {
                    if (this.rawData.hasOwnProperty(k)&& this.doctor.hasOwnProperty(k)) {
                        if (this.rawData[k] !== this.doctor[k]) {
                            if (!this.diffData) {
                                this.diffData = {};
                                ifChange = false
                            }
                            this.diffData[k] = this.doctor[k];
                            ifChange = true
                        }
                    }
                }
                if (ifChange == true){
                    try {
                        await axios.put(window.serverURL + 'account/doctor', {
                            Name: this.diffData.name,
                            Username: this.diffData.username,
                            Department: this.diffData.department,
                            Email: this.diffData.email,
                        })
                        this.$message({
                            message: '个人信息修改成功！',
                            type: 'success'
                        })
                        this.loading = false
                        this.getUser()
                    } catch (e) {
                        this.loading = false
                        this.$message({
                            message: '个人信息修改失败，请再次尝试提交。',
                            type: 'error'
                        })
                    }
                }
            },
            submitPhone(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        axios.put(window.serverURL + 'account/doctor',{
                           Phone: this.newDoctor.phone, 
                        }).then(()=>{
                            this.$message({
                                message:'手机号修改成功！',
                                type:'success'
                            })
                            this.getUser()
                            this.newDoctor.phone = ''
                            this.dialogFormVisible = false
                        }).catch(()=>{
                            this.$message({
                                message:'手机号修改失败，请再次尝试提交。',
                                type:'error'
                            })
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.updateUser()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async getUser(){
                try {
                    let res = await axios.get(window.serverURL + 'account/doctor')
                    if (res.data.length !== 0) {
                        this.rawData = { ...res.data.data }
                        this.doctor = res.data.data
                        this.$addStorageEvent("username", this.doctor.username);
                    }
                } catch (e) {
                        this.loading = false
                        this.$message({
                            message: '个人信息获取失败，请刷新重试。',
                            type: 'error'
                    })
                }
            },
        },
        created() {
            if (this.$route.path !== '/login'|| this.$route.path !== '/register') {
                this.loading = true
                axios.get(window.serverURL + 'verify/doctor')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.getUser()
        }
    }
</script>

<style scoped>
</style>
