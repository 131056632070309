<template>
    <div id="PJManage" :style="containerStyle" style="font-family: 微软雅黑; background-color: #ffffff">
        <el-container>
            <el-header  class="header" height="4rem" style="background-color: #dca571; position: fixed; width: 100%; z-index:3">
                <el-row style="width:100%">
                    <el-col :xs="4">
                        <div id="bars" @click="dropDownShow = !dropDownShow">
                            <div style="height: 18px"></div>
                            <i style="font-size: 25px; color:#F5F5F5" class="el-icon-s-fold"/>
                        </div>
                    </el-col>
                    <el-col :xs="{span:15}" :sm="{span:12}" :md="{span:10}">
                         <div style="height: 4rem; padding: 0px; background-color: #dca571; float: left; text-align: left; ">
                            <div style="height: 12px"></div>
                            <div class="title" style="line-height: 40px; font-weight: 600; color: #ffffff;">
                                {{"电子问卷调查平台"}}
                            </div>
                        </div>   
                    </el-col>
                    <el-col :xs="5" :sm="{span:3, offset:8}" :md="{span:3, offset:11}">
                        <div v-show="doctorJudge">
                            <el-dropdown style="color: #fff; font-size: 18px; margin-top: 1.3rem">
                                <span class="el-dropdown-link">{{"你好，"}}{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item divided @click.native="toPersonalCenter">个人信息</el-dropdown-item>
                                    <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div v-show="!doctorJudge">
                            <el-row>
                                <el-col :span="13">
                                    <div class="header" style="float: right"><span style="display: inline; float:right; color: #fff; margin-top:1rem; font-size: 26px; margin-right: 4rem">量表评定</span></div>
                                </el-col>
                                <el-col :span="11">
                                    <el-dropdown style="color: #fff; font-size: 18px; float: right; margin-right:6rem; margin-top: 1.3rem">
                                        <span class="el-dropdown-link">{{"你好，"}}{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item divided @click.native="toPersonalCenter">个人信息</el-dropdown-item>
                                            <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
            </el-header>
            <el-container>
                <el-aside :style="asideStyle" v-show="doctorJudge" >
                    <div class="header">
                         <!-- 顶部导航 -->
                        <el-menu class="container" style="margin-top:4rem">
                            <template class="container-left-ul">
                                <el-menu-item :class="$store.state.activeName === item.activeName ? 'menu-item-active' : 'container-left-li'" @click="toActiveMenuItem(item)"
                                    v-for="(item, index) in leftMenuList" :key="index" id="menuTitle">
                                    {{ item.titleName }}
                                </el-menu-item>
                            </template>
                        </el-menu>
                                <!-- 下拉菜单 -->
                        <transition name="dropdown-fade-show">
                            <el-menu v-show="dropDownShow" class="dropdown">
                                <el-menu-item class="dropdown-top-ul" @click="toActiveMenuItem(item)"
                                    v-for="(item, index) in leftMenuList" :key="index">
                                    {{ item.titleName }}
                                </el-menu-item>
                            </el-menu>
                        </transition>
                    </div>
                </el-aside>
                <el-main class="main" :style="mainStyle">
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                dropDownShow: false, // 控制下拉菜单显示
                leftMenuList: [ // 左侧菜单内容
                    { activeName: '量表评定', titleName: '量表评定', activeUrl: '/home/scale' },
                    { activeName: '项目管理', titleName: '项目管理', activeUrl: '/home/project' },
                    { activeName: '病例管理', titleName: '病例管理', activeUrl: '/home/patient' }
                ],
                activeName: '', // 导航栏激活名称
                doctorJudge:false,
                username:'',
                asideStyle: {height: window.innerHeight / 16 -9 + 'rem', width: 10},
                menuStyle: {height: window.innerHeight / 16 - 9 + 'rem'},
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                containerStyle: {height: window.innerHeight / 16 + 'rem'},
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
            window.addEventListener('setItem', (e) => {
                if (e.key == "username"){
                    this.username = e.newValue
                }
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
                this.containerStyle.height = window.innerHeight / 16 + 'rem';
                this.asideStyle.height = window.innerHeight / 16 + 'rem';
                this.menuStyle.height = window.innerHeight / 16 - 5 + 'rem';
            },
            toPersonalCenter() {
                if (this.doctorJudge === true) {
                    this.$router.push({path:'/doctorPersonalCenter'})
                } else {
                    this.$router.push({path:'/personalCenter'})
                }
            },
            logout() {
                this.$store.commit('logOut')
                this.$router.push('/login')
            },
            toActiveMenuItem (item) { // 激活导航菜单
                this.activeName = item.titleName
                this.$router.push(item.activeUrl)
                this.dropDownShow = false
            },
        },
        async created() {
            this.username = localStorage.getItem('username')
            if (localStorage.getItem("role") == "2") {
                this.doctorJudge = true
            }
            if (this.$route.path !== '/login') {
                this.loading = true
                try {
                    let res = await axios.get(window.serverURL + 'verify/doctor')
                    if (res.status === 200) {
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$store.commit('needLogin')
                } catch (e) {
                    this.loading = false
                    this.$router.push('/login')
                }
            }
        }
    }
</script>

<style scoped>
    .title {
        border-radius: 20px;
    }
    .main{
        padding: 0;
    }
    .menu {
        border: none;
    }

/* 把768改为767适应pad */
@media screen and (max-width: 767px) {
    .container-left-li {
        display: none !important;
    }
    #menuTitle{
        padding: 0px 10px;
    }
    .el-aside {
        width: auto;
    }
    .title{
        font-size: 16px;
    }
}
@media screen and (min-width: 767px) {
    #bars {
        display: none;
    }
    .dropdown {
        display: none;
    }
    #menuTitle{
        padding: 0px 50px;
    }
    .el-aside {
        width: 200px;
    }
    .title{
        font-size: 23px;
        margin-left: 10px
    }
}
/* ipad pro 1366 */
@media screen and (min-width:1367px) {
    .el-aside {
        width: 400px;
    }
    .title{
        font-size: 23px;
        float: left;
        margin-left: 80px;
    }
}
</style>