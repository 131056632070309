import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import axios from 'axios'
import '../theme/index.css'
import qs from 'qs'
import router from './router/index.js'
import QS from 'qs'
import Vuex from 'vuex'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Vuex);
Vue.prototype.qs = QS;
Vue.use(ElementUI);
Vue.prototype.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.copyrequestbody = true;

const store = new Vuex.Store({
    state: {
        needLogin: false,
        dialogVisible: false,
        patientId: '',
        patientName: '',
        username: '',
        name: '',
        patientList: [],
        role: '',
        token: '',
        AnswerList: [],
        pdfdata: {},
    },
    mutations: {
        afterLogin(state,username,role) {
            state.needLogin = false
            state.username = username
            state.role = role
        },
        needLogin(state) {
            state.needLogin = true
        },
        afterLog(state) {
            state.dialogVisible = false
        },
        logOut(state){
            localStorage.removeItem('token');
            state.token = null;
            localStorage.removeItem('name');
            localStorage.removeItem('username');
            localStorage.removeItem('role');
        },
        needLog(state) {
            state.dialogVisible = true
        },
        changeId(state, id) {
            state.patientId = id
        },
        changeName(state, name) {
            state.patientName = name
        },
        changeUserName(state, username) {
            state.username = username
        },
        changePatientList(state, patientlist) {
            state.patientList = patientlist
        },
        setToken (state,token) {
            state.token = token;
            localStorage.setItem("token", token);     //存储token
        },
    }
})
// const serverURL =  'http://localhost:38870/'
const serverURL =  'https://api.qp.bjbigiq.com/'
window.serverURL = serverURL

new Vue({
    el: '#app',
    store,
    components: { App },
    router,
    render: h => h(App),
    created: function () {
        this.initConn();
    },
    data: {
        server: "ws://10.107.2.78:38861",
        socket: null
    },
    methods: {
        initConn() {
            let socket = new WebSocket(this.server);
            this.socket = socket
            this.socket.onmessage = this.onMessage;
            this.socket.onopen = this.onOpen;
        },
        onOpen() {
            console.log("connect success")
            this.onSend()
        },
        onSend() {
            var result = {'type': "AD", 'score': 10}
            this.socket.send(JSON.stringify(result))
        },
        onMessage(e) {
            console.log(e, "=====")
        }
    }
});

// new Vue({
//     el: '#app',
//     store,
//     components: { App },
//     router,
//     render: h => h(App)
// });
  
Vue.prototype.accountLogin = async function (userName, passWord, posturl, PhoneUnique) {
    if (this.userName === '' || this.passWord === '') {
        this.$message({
            message: '账户或密码不可为空',
            type: 'warning'
        });
        return
    }
    this.loading = true
    try {
        var res = await axios.post(window.serverURL + posturl, qs.stringify({
            username: userName,
            password: passWord,
        }))
        localStorage.setItem('role', res.data.data.Role)
        localStorage.setItem('username', userName)
        if (res.data.data.Role == "1"){
            localStorage.setItem('name', res.data.data.Patient.name)
        }else{
            localStorage.setItem('name', res.data.data.Doctor.name)
        }
        this.$store.commit('afterLogin', userName, res.data.data.role)
        this.loading = false
        this.$store.commit('setToken', getCookie('token'))
        if (localStorage.getItem("role") == 1) {
            if (PhoneUnique == false) {
                this.$router.push('/personalCenter')
                this.$alert('用户名和密码已更新，其他信息系统已录入，若不正确请自行修改。', '个人信息确认', {
                    confirmButtonText: '确定',
                });
            } else {
                this.$router.push('/questionnaire')
            }
        }else if (localStorage.getItem("role") == 2){
            this.$router.push('/home/scale')
        }
    } catch (err) {
        this.loading = false
        this.$message.error('账户或密码错误')
    }
}

function getCookie(cname){
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++)
    {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
}

 Vue.prototype.$addStorageEvent = function (key, data) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function (k, val) {
            localStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        }
    }
    return storage.setItem(key, data);
}

Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'watchStorage') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
  }