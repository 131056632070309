<template>
    <div>
        <el-container style="background-color: #efefef">
            <el-main style="margin: 0px; padding: 0px; width:100%" :style="mainStyle">
                <div :style="outStyle">
                    <el-scrollbar style="height: 100%;">
                        <div style="width: 80%; margin-right: auto; margin-left: auto; margin-top: 120px; text-align: center; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 2rem">
                            <el-card class="box-card" style="width: 340px; height: 340px; margin: 15px; float: left" v-for="(questionnaireList, index) in questionnaireLists" :key="index">
                                <div style="margin-top: 50px; color: #dca571; font-weight: 600; line-height: 50px; font-size: 26px;letter-spacing: 1px; font-family: 'Adobe 黑体 Std R',serif">{{questionnaireList.title}}</div>
                                <div style="margin-top: 2px;color: #dca571; font-weight: 500; line-height: 50px; font-size: 24px;letter-spacing: 1px; font-family: Helvetica,serif; font-style:italic;">{{questionnaireList.type}}</div>
                                <el-button type="primary" style="margin-top: 35px" @click="toTest(index)">开始测试</el-button>
                            </el-card>
                        </div>
                    </el-scrollbar>
                </div>
            </el-main>
            <el-header class="header" height="4rem" style="background-color: #dca571; position: fixed; width: 100%; z-index: 1">
                <el-row>
                    <el-col :span="8" :offset="8">
                        <div style="height:1rem;"> </div>
                        <div class="header"><span style="display: inline; color: #fff; font-size: 26px; font-weight: 600;">量表评定</span></div>
                    </el-col>
                    <el-col :span="8">
                        <el-dropdown style="color: #fff; font-size: 15px; margin-top: 2rem">
                            <span class="el-dropdown-link">{{"你好，"}}{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item divided @click.native="toPersonalCenter">个人信息</el-dropdown-item>
                                <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </el-header>
        </el-container>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                questionnaireLists: [],
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                input: '',
                outStyle: {height: window.innerHeight / 16 + "rem",},
                username:'',
                questionnaires: [],
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
                this.outStyle.height = window.innerHeight / 16 + 'rem';
            },
            toTest(index) {
                this.$router.push({path:'/questionnaire/' + this.questionnaireLists[index].type})
                // for (let i = 0; i < this.questionnaires.length; i++) {
                //     if (this.questionnaires[i].type === this.questionnaireLists[index].type) {
                //         let questionnaire = this.questionnaires[i]
                //         axios.post(window.serverURL + 'questionnaire', {ID: questionnaire.id, QuestionnaireType: questionnaire.type, QuestionnaireTitle:questionnaire.title, Mentor: questionnaire.mentor, MentorJudge: questionnaire.mentorJudge})
                //             .then(response => {
                //                 this.$message({
                //                     message: '问卷数据提交成功！',
                //                     type: 'success'
                //                 })
                //                 this.loading = false
                //             })
                //             .catch(error => {
                //                 this.loading = false
                //                 this.$message({
                //                     message: '问卷数据提交失败，请再次尝试提交。',
                //                     type: 'error'
                //                 })
                //             })
                //         for (let j = 0; j < questionnaire.groupQuestions.length; j++) {//提交问题
                //             for(let k = 0; k < questionnaire.groupQuestions[j].messages.length; k++){
                //                 let optionString = JSON.stringify(questionnaire.groupQuestions[j].messages[k].options)
                //                 let headersString = JSON.stringify(questionnaire.groupQuestions[j].messages[k].headers)
                //                 axios.post(window.serverURL + 'question', {
                //                     QuestionnaireID: questionnaire.id,
                //                     Group: questionnaire.groupQuestions[j].group,
                //                     GroupTitle: questionnaire.groupQuestions[j].title,
                //                     QuestionID: questionnaire.groupQuestions[j].messages[k].id,
                //                     QuestionType: questionnaire.groupQuestions[j].messages[k].type,
                //                     Content: questionnaire.groupQuestions[j].messages[k].content,
                //                     Supplement: questionnaire.groupQuestions[j].messages[k].supplement,
                //                     TableHeader: headersString,
                //                     Options: optionString
                //                 })
                //                     .then(response => {
                //                         this.$message({
                //                             message: '问题数据提交成功！',
                //                             type: 'success'
                //                         })
                //                         this.loading = false
                //                     })
                //                     .catch(error => {
                //                         this.loading = false
                //                         this.$message({
                //                             message: '问题数据提交失败，请再次尝试提交。',
                //                             type: 'error'
                //                         })
                //                     })
                //             }
                //         }
                //     }
                // }
            },
            toPersonalCenter() {
                this.$router.push({path:'/personalCenter'})
            },
            logout() {
                this.$store.commit('logOut')
                this.$router.push('/login')
            },
            async searchAllScale() {
                try {
                    let res = await axios.get(window.serverURL + "questionnaire")
                    this.questionnaireLists = res.data.data
                    this.loading = false
                } catch (e) {
                    this.$message({
                        message: '获取量表信息失败，请刷新重试',
                        type: 'error'
                    });
                }
            }
        },
        created() {
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify/patient')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.searchAllScale()
            this.username = localStorage.getItem('username')
        },
    }
</script>

<style scoped>
    .box-card {
        cursor: pointer;
        transition: all 0.6s;
        overflow: visible;
    }
    .box-card:hover {
        transform: scale(1.05);
    }
    .el-main /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    #app {
        background-color: #efefef;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        color: #2c3e50;
    }
    body {
        margin: 0;
        padding: 0;
    }
    .header{
        display: inline-block;
    }
</style>
