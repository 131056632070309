<template>
    <div id="commonSurvey">
        <el-container style="width: 100%; border: 0px solid #eee; background-color: #efefef">
            <el-header height="4rem" style="background-color: #dca571; position: fixed; width: 100%; z-index: 3" >
                <el-row>
                    <el-col :span="12" :offset="6">
                        <div style="height: 12px"></div>
                        <div class="header"><span style="display: inline; color: #fff;">{{questionnaire.title}}</span></div>
                    </el-col>
                    <el-col :span="6">
                        <el-dropdown style="color: #fff; margin-top: 1.3rem" class="dropDown">
                            <span class="el-dropdown-link">{{"你好，"}}{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item divided @click.native="toPersonalCenter">个人信息</el-dropdown-item>
                                <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>  
                </el-row>
            </el-header>
            <el-main style="background-color: #efefef; margin-right: 0; margin-left: 0; margin-top: 5rem; padding: 0">
                <el-row :gutter="10">
                    <el-col :xs="{span:22, offset:1}" :sm="{span:16, offset:4}" :md="{span:20, offset:2}">
                        <div direction="vertical"
                            id="cardContainer"
                            style="margin: 4rem auto 7rem; background-color: #fff;">
                            <el-card v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="groupQuestion.group">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="{span:22, offset:1}" >
                                        <el-form
                                            label-position="top"
                                            label-width="550px"
                                            style="font-weight: 600; text-align: left; margin: 3rem auto 3rem;">
                                            <div style="color: #555555; font-size: 20px; font-weight: 600; width: 95%; text-align: center; background-color: #fff; padding-left: 3.6%; margin: 1rem">
                                                {{groupQuestion.title}}
                                            </div>
                                            <div class="questionBox" id="questionBox" v-for="(message, qIndex) in groupQuestion.messages" :key="message.content" >
                                                <div class="formBox">
                                                    <!--                            单选题型-->
                                                    <el-form-item :label="message.id + '. ' + message.content" v-if="message.type === '单选题'">
                                                        <el-radio-group v-model="userAnswer[group][qIndex].choiceAnswer">
                                                            <el-radio v-for="(option, optionIndex) in groupQuestion.messages[qIndex].options" :key="optionIndex" :label="optionIndex">{{option}}</el-radio>
                                                        </el-radio-group>
                                                        <el-input
                                                            v-if="groupQuestion.messages[qIndex].supplement"
                                                            v-model="userAnswer[group][qIndex].supplement"
                                                            type="textarea"
                                                            :autosize="{minRows: 1, maxRows: 100}"
                                                            placeholder="请在此输入内容">
                                                        </el-input>
                                                    </el-form-item>
                                                    <!--                            多选题型-->
                                                    <el-form-item :label="message.id + '. ' + message.content" v-if="message.type === '多选题'">
                                                        <el-checkbox-group v-model="userAnswer[group][qIndex].listAnswer">
                                                            <el-checkbox v-for="(option, optionIndex) in groupQuestion.messages[qIndex].options" :label="optionIndex" :key="optionIndex">{{option}}</el-checkbox>
                                                        </el-checkbox-group>
                                                        <el-input
                                                            v-if="groupQuestion.messages[qIndex].supplement"
                                                            v-model="userAnswer[group][qIndex].supplement"
                                                            type="textarea"
                                                            :autosize="{minRows: 1, maxRows: 100}"
                                                            placeholder="请在此输入内容">
                                                        </el-input>
                                                    </el-form-item>
                                                    <!--                            简答题型-->
                                                    <el-form-item v-if="message.type === '问答题'&& questionnaire.title ==='快感缺失评定量表'">
                                                        <el-input
                                                            v-model="userAnswer[group][qIndex].supplement"
                                                            type="textarea"
                                                            :autosize="{minRows: 2, maxRows: 100}"
                                                            placeholder="请在此输入内容">
                                                        </el-input>
                                                    </el-form-item>
                                                    <el-form-item :label="message.id + '. ' + message.content" v-if="message.type === '问答题'&& questionnaire.title !='快感缺失评定量表'">
                                                        <el-input
                                                            v-model="userAnswer[group][qIndex].supplement"
                                                            type="textarea"
                                                            :autosize="{minRows: 2, maxRows: 100}"
                                                            placeholder="请在此输入内容">
                                                        </el-input>
                                                    </el-form-item>
                                                    <el-form-item :label="message.id + '. '+ message.content" v-if="message.type === '联动题'">
                                                        <el-row>
                                                            <el-col :xs="{span:18, offset:3}" :sm="10" :md="5">
                                                                <div class="block">
                                                                    <div class="demonstration" v-show="message.options.optionTitleJudge">{{" "+message.options.optionTitle[0]+" / "+message.options.optionTitle[1]}}</div>
                                                                    <br>
                                                                    <el-cascader
                                                                        v-model="userAnswer[group][qIndex].listAnswer"
                                                                        :options="message.options.structure"
                                                                        clearable
                                                                    >
                                                                    </el-cascader>
                                                                    <el-input
                                                                        v-if="groupQuestion.messages[qIndex].supplement"
                                                                        v-model="userAnswer[group][qIndex].supplement"
                                                                        type="textarea"
                                                                        :autosize="{minRows: 1, maxRows: 100}"
                                                                        placeholder="请在此输入内容">
                                                                    </el-input>
                                                                </div>
                                                            </el-col>
                                                        </el-row>
                                                    </el-form-item>
                                                    <el-form-item :label="message.id + '. '+ message.content" v-if="message.type === '联动多选题'">
                                                        <el-row>
                                                            <el-col :xs="24" :sm="7" :md="4">
                                                                <div>
                                                                    <div class="demonstration1">{{message.options.optionTitle[0]}}</div>
                                                                    <el-select 
                                                                        v-model="userAnswer[group][qIndex].listAnswer[0][0]"
                                                                        placeholder="请选择"
                                                                        @change="(value) => multipleSelect1(value,group,qIndex)">
                                                                        <el-option
                                                                            v-for="(option,index) in message.options.structure"
                                                                            :key="index"
                                                                            :label="option.label"
                                                                            :value="option.label">
                                                                        </el-option>
                                                                    </el-select>
                                                                </div>
                                                            </el-col>
                                                            <el-col :xs="20" :sm="{span:7, offset:1}" :md="4">
                                                                <div>
                                                                    <div class="demonstration2" v-if="multipleJudge[group][qIndex][pick[group][qIndex]]" >{{message.options.optionTitle[1]}}</div>
                                                                    <el-select 
                                                                        v-model="answer[group][qIndex].listAnswer[1]"
                                                                        multiple
                                                                        placeholder="请选择"
                                                                        v-if="multipleJudge[group][qIndex][pick[group][qIndex]]"
                                                                        @change="(value) => multipleSelect2(value,group,qIndex,pick[group][qIndex])">
                                                                        <el-option
                                                                            v-for="(item,index) in message.options.structure[pick[group][qIndex]].children"
                                                                            :key="index"
                                                                            :label="item.label"
                                                                            :value="item.label">
                                                                        </el-option>
                                                                    </el-select>
                                                                </div>
                                                            </el-col>
                                                            <el-col :xs="20" :sm="{span:7, offset:1}" :md="4" >
                                                                <div>
                                                                    <div class="demonstration3" v-if="multipleJudge3[group][qIndex]">{{message.options.optionTitle[2]}}</div>
                                                                    <el-select 
                                                                        clearable
                                                                        v-model="answer[group][qIndex].listAnswer[2][0]"
                                                                        placeholder="请选择"
                                                                        v-if="multipleJudge3[group][qIndex]"
                                                                        @change="(value) => multipleSelect3(value,group,qIndex,pick[group][qIndex],pick2[group][qIndex][pick[group][qIndex]])">
                                                                        <el-option
                                                                            v-for="(item,index) in message.options.structure[pick[group][qIndex]].children[pick2[group][qIndex][pick[group][qIndex]]].child"
                                                                            :key="index"
                                                                            :label="item.label"
                                                                            :value="item.label">
                                                                        </el-option>
                                                                    </el-select>
                                                                </div>
                                                            </el-col>
                                                        </el-row>
                                                        <el-input
                                                            v-if="groupQuestion.messages[qIndex].supplement"
                                                            v-model="userAnswer[group][qIndex].supplement"
                                                            type="textarea"
                                                            :autosize="{minRows: 1, maxRows: 100}"
                                                            placeholder="请在此输入内容">
                                                        </el-input>
                                                    </el-form-item>
                                                    <el-form-item :label="message.id + '. ' + message.content" v-if="message.type === '表格题'">
                                                        <div class="button" style="width:6%;">
                                                            <P><el-button class="el-icon-plus" @click.prevent="addRow(group, qIndex)"></el-button></P>
                                                        </div>
                                                        <el-table border :data="userAnswer[group][qIndex].tableData" style="margin-left: auto; margin-right: auto; margin-bottom: 1rem" >
                                                            <el-table-column label="序号"  type="index" align="center"></el-table-column>
                                                            <el-table-column v-for="(header, index) in message.headers" :key="index" :label="header">
                                                                <template slot-scope="scope">
                                                                    <el-input v-model="userAnswer[group][qIndex].tableData[scope.$index][index]"></el-input>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="操作">
                                                                <template slot-scope="scope">
                                                                    <el-button
                                                                        @click.native.prevent="deleteRow(scope.$index, userAnswer[group][qIndex].tableData)"
                                                                        type="text"
                                                                        size="small">
                                                                        移除
                                                                    </el-button>
                                                                </template>
                                                            </el-table-column>
                                                        </el-table>
                                                    </el-form-item>
                                                    <el-form-item :label="message.id + '. ' + message.content" v-if="message.type === '时间选择题'">
                                                        <el-time-select
                                                            v-model="userAnswer[group][qIndex].time"
                                                            :picker-options="{start: '00:00', step: '00:10',end: '23:50'}"
                                                            placeholder="请选择时间"
                                                            value-format="timestamp">
                                                        </el-time-select>
                                                    </el-form-item>
                                                    <el-form-item :label="message.id + '. ' + message.content" v-if="message.type === '日期选择题'">
                                                        <el-date-picker
                                                            v-model="userAnswer[group][qIndex].date"
                                                            type="date"
                                                            placeholder="请选择日期"
                                                            format="yyyy 年 MM 月 dd 日"
                                                            value-format="yyyy-MM-dd">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                        </el-form>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </div>
                    </el-col>
                </el-row>
                <div v-loading="loading" style="margin-bottom: 4rem">
                    <el-button @click="submitData" type="primary" icon="el-icon-document" :disabled="disabledSubmit">提交</el-button>
                </div>
            </el-main>
        </el-container>
        <el-dialog
                title="指导语"
                :visible.sync="dialogVisible"
                width="300px"
                :close-on-click-modal="false"
                center>
            {{questionnaire.mentor}}
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                disabledSubmit:true,
                pick: [],
                pick2: [],
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                dialogVisible: false,
                loading: false,
                interlockRecursion: [],
                questionnaire: {},
                message: {},
                groupQuestions: [],
                groupQuestion: {},
                questionName: "",
                questionPhone: "",
                userAnswer: [],
                rowNum: 1,
                tableData: [],
                multipleArray: [],
                listAnswer: [],
                multipleJudge: [],
                multipleJudge2: [],
                multipleJudge3: [],
                firstPick: 0,
                secondPick: 0,
                answer: [],
                name: "",
                username: '',
                label: [],
                AnswerList: [],
                pdfTable: [],
            }
        },
        methods: {
            deleteRow(index, rows) {
                rows.splice(index, 1)
            },
            addRow(group,qIndex) {
                this.userAnswer[group][qIndex].tableData.push([]) //tabledata是二维数组
                this.rowNum += 1;
            },
            async submitData() {
                this.loading = true
                console.log("gethere")
                if (this.questionnaire.title != "童年不良经历问卷" && this.questionnaire.title != "匹茨堡睡眠质量指数量表" && this.questionnaire.title != "生活事件量表" && this.questionnaire.title != "一般信息" && this.questionnaire.title != "心理健康问卷") {
                    for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
                        for (let j = 0; j < this.userAnswer[i].length; j++) {
                            // if (j === 0 || j === 1 || j === 2 || j === 3) {
                            //     continue
                            // }
                            if (this.userAnswer[i][j].choiceAnswer === -1 && this.userAnswer[i][j].listAnswer.length === 0 && this.userAnswer[i][j].supplement === "") {
                                this.$message({
                                    message: "请回答全部问题后再提交问卷。",
                                    type: 'warning'
                                })
                                this.loading = false
                                return
                            }
                        }
                    }
                }
                try {
                    let res = await axios({
                        method: 'post',
                        url: window.serverURL + 'answer',
                        data: {
                            Name: this.name,
                            UserName: this.username,
                            SurveyName: this.questionnaire.title,
                            SurveyType: this.questionnaire.type,
                            SurveyData: this.userAnswer
                        },
                    })
                    this.$message({
                        message: '问卷提交成功！',
                        type: 'success'
                    })
                    if (this.questionnaire.type == "PANAS" || this.questionnaire.type == "CERQ" || this.questionnaire.type == "DARS"|| this.questionnaire.type == "PDQ-D"|| this.questionnaire.type == "HAMD"|| this.questionnaire.type == "SDS"|| this.questionnaire.type == "SAS&SDS" || this.questionnaire.type == "CAQ" || this.questionnaire.type == "CTQ" || this.questionnaire.type == "LES" || this.questionnaire.type == "PHQ-9" || this.questionnaire.type == "PSQI" || this.questionnaire.type == "MHQ" || this.questionnaire.type == "Rutter" || this.questionnaire.type == "SCL-90") {
                        if (res.data.data.TableData) {
                            var TableHeader = []
                            for (let i = 0; i < res.data.data.TableData[0].length; i++) {
                                var header = {}
                                header.prop = "prop"+ i 
                                header.propName = res.data.data.TableData[0][i]
                                TableHeader.push(header)
                            }   
                            for (let i = 1; i < res.data.data.TableData.length; i++) {//从TableData数组的第二个元素开始，是表格内的数据
                                var propObject = {}
                                for (let j = 0; j < res.data.data.TableData[0].length; j++) {
                                    this.$set(propObject, "prop"+ j, res.data.data.TableData[i][j])
                                }
                                this.pdfTable.push(propObject) 
                            }
                            res.data.data.TableData = this.pdfTable
                            this.$set(res.data.data, "TableHeader", TableHeader)
                        }
                        var value = Object.values(res.data.data)
                        var key = Object.keys(res.data.data)
                        for (let i = 0; i < key.length; i++) {
                            this.$set(this.$store.state.pdfdata, key[i], value[i])
                        }
                        localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                        this.$router.push({path:'/report/' + this.questionnaire.type})
                    } else {
                        if (localStorage.getItem("role") == 2) {
                            this.$router.push({path:'/home/scale'})
                        } else {
                            this.$router.push({path:'/questionnaire'})
                        }
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '问卷提交失败，请再次尝试提交。',
                        type: 'error'
                    })
                }
            },
            multipleSelect1(value,group,qIndex) {
                var structure = this.questionnaire.groupQuestions[group].messages[qIndex].options.structure
                for (let k = 0; k < structure.length; k++) {
                    if (structure[k].label === value) {//遍历structure,当其中一项的label等于选中的value时，即可确定该value在structure的索引
                        this.pick[group][qIndex] = k
                        if (structure[k].children) {//该选项下有下一级选项时
                            this.multipleJudge[group][qIndex][this.pick[group][qIndex]] = true //bool  multipleJudge控制第二级联动框的显示 
                        } else {//该选项下无下一级选项时
                            this.multipleJudge[group][qIndex][this.pick[group][qIndex]] = false
                            this.multipleJudge3[group][qIndex] = false
                            this.answer[group][qIndex].listAnswer = []
                        }
                    } 
                }
            },
            multipleSelect2(value, group, qIndex, k) {
                var structure = this.questionnaire.groupQuestions[group].messages[qIndex].options.structure
                this.userAnswer[group][qIndex].listAnswer = []
                this.userAnswer[group][qIndex].listAnswer.push([])
                this.userAnswer[group][qIndex].listAnswer[0][0] = structure[this.pick[group][qIndex]].label
                if (structure[k].children) {
                    for (let v = 0; v < value.length; v++) {
                        for (let t = 0; t < structure[k].children.length; t++) {
                            if (this.userAnswer[group][qIndex].listAnswer.length < value.length) { 
                                this.userAnswer[group][qIndex].listAnswer.push([])
                            }
                            if (structure[k].children[t].label === value[v]) {//判断选中的值是否在某一个选项的联动选项中  此语句可以在问题数据中找到用户选择的各个值  确定了好几个t
                                this.userAnswer[group][qIndex].listAnswer[v][0] = structure[k].label
                                this.userAnswer[group][qIndex].listAnswer[v][1] = structure[k].children[t].label
                                if (structure[k].children[t].child) {//某一个选项的联动选项  有第三级联动选项
                                    this.pick2[group][qIndex][k] = t
                                    this.answer[group][qIndex].listAnswer.push([])
                                }
                            }
                            if (structure[k].children[t].child) {
                                let a = structure[k].children[t].label
                                let index = value.indexOf(a);
                                if (index >= 0) {
                                    this.multipleJudge3[group][qIndex] = true
                                } else {
                                    this.multipleJudge3[group][qIndex] = false
                                }
                            }
                        }
                    }
                }
            },
            multipleSelect3(value, group, qIndex, k, t) {
                var structure = this.questionnaire.groupQuestions[group].messages[qIndex].options.structure
                for (let i = 0; i < this.userAnswer[group][qIndex].listAnswer.length; i++) {
                    if (this.userAnswer[group][qIndex].listAnswer[i].length == 3) {
                        this.userAnswer[group][qIndex].listAnswer[i] = this.userAnswer[group][qIndex].listAnswer[i].slice(0,2)
                    }
                }
                var a = ""
                if (structure[k].children[t].child) {
                    for (let l = 0; l < structure[k].children[t].child.length; l++) {
                        if (structure[k].children[t].child[l].label === value) {
                            a = value
                        }
                    }
                }
                for (let i = 0; i < this.userAnswer[group][qIndex].listAnswer.length; i++) {
                    if (this.userAnswer[group][qIndex].listAnswer[i][1] == structure[k].children[t].label) {//当listanswer其中一个是“抗精神病”，就在数组此处push进第三级选项
                        this.userAnswer[group][qIndex].listAnswer[i].push(a)
                    }
                }
            },
            toPersonalCenter() {
                if (localStorage.getItem("role") == 2) {
                    this.$router.push({path:'/doctorPersonalCenter'})
                } else {
                    this.$router.push({path:'/personalCenter'})
                }
            },
            logout() {
                this.$store.commit('logOut')
                this.$router.push('/login')
            }
        },
        async created() {
            if (localStorage.getItem('role') == "1") {
                this.disabledSubmit = false
            }
            this.name = localStorage.getItem('name')
            this.username = localStorage.getItem('username')
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.questionnaire.type = this.$route.params.type
            try {
                if (this.questionnaire.type == "SAS&SDS") {
                    var res = await axios.get(window.serverURL + "question?name=" + "SAS%26SDS")
                } else {
                    res = await axios.get(window.serverURL + "question?name=" + this.questionnaire.type)
                }
                var temp = res.data.data
                for (let i = 0; i < temp.length; i++) {
                    this.questionnaire = {}
                    this.$set(this.questionnaire,"id",temp[0].id)
                    this.$set(this.questionnaire,"type",temp[0].type)
                    this.$set(this.questionnaire,"title",temp[0].title)
                    this.$set(this.questionnaire,"mentor",temp[0].mentor)
                    this.$set(this.questionnaire,"mentorJudge",temp[0].mentorJudge)
                    this.questionnaire.groupQuestions = []
                    var l = temp.length
                    var grouplength = temp[l-1].group + 1
                    for (let j = 0; j < grouplength; j++ ) {
                        var groupQuestion = {}
                        groupQuestion.group = j
                        for (let t = 0; t < temp.length; t++) {
                            var g = temp[t].group
                            if (groupQuestion.group == g) {
                                groupQuestion.title = temp[t].group_title
                            }
                        }
                        this.questionnaire.groupQuestions.push(groupQuestion)
                        this.questionnaire.groupQuestions[j].messages = []
                        for (let k = 0; k < temp.length; k++) {
                            if (temp[k].group == this.questionnaire.groupQuestions[j].group) {
                                var message = {}
                                message.id = temp[k].question_id
                                message.type = temp[k].question_type
                                message.content = temp[k].content
                                if (temp[k].tableheader.length != 0) {
                                    message.headers = JSON.parse(temp[k].tableheader)
                                }
                                message.supplement = temp[k].supplement
                                if (temp[k].options.length != 0) {
                                    message.options = JSON.parse(temp[k].options)
                                }
                                this.questionnaire.groupQuestions[j].messages.push(message)
                            }
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                this.loading = false
                this.$message({
                    message: '获取量表数据失败，请刷新重试。',
                    type: 'error'
                })
            }

            // for (let i = 0; i < this.questionnaires.length; i++) {
            //     if (this.questionnaires[i].type === this.$route.params.type) {
            //         this.questionnaire = this.questionnaires[i]
            //     }
            // }

            if (this.questionnaire.mentorJudge === true) {
                this.dialogVisible = true
            }
            for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
                this.userAnswer.push([])
                this.answer.push([])
                this.pick.push([])
                this.pick2.push([])
                this.multipleJudge.push([])
                this.multipleJudge3.push([])
                for (let j = 0; j < this.questionnaire.groupQuestions[i].messages.length; j++) {
                    this.pick[i].push([])
                    this.pick2[i].push([])
                    this.multipleJudge[i].push([])
                    this.multipleJudge3[i].push([])
                    this.answer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer: [], tableData: [], time:"", date:"", supplement: ""})
                    this.userAnswer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer: [], tableData: [], time:"", date:"", supplement: ""})
                    switch (this.questionnaire.groupQuestions[i].messages[j].type) {
                        case "联动多选题":
                            this.answer[i][j].listAnswer.push([])
                            this.userAnswer[i][j].listAnswer.push([])
                            for (let k = 0; k < this.questionnaire.groupQuestions[i].messages[j].options.structure.length; k++) {
                                this.pick2[i][j].push([])
                                if (this.questionnaire.groupQuestions[i].messages[j].options.structure[k].children) {
                                    this.multipleJudge[i][j][k] = false
                                    this.multipleJudge3[i][j] = false
                                    this.pick[i][j] = 0
                                    this.pick2[i][j][this.pick[i][j]] = 0
                                }
                            }
                            break
                        default:
                            break
                    }
                }
            }
        },
        mounted() {
            if (this.width > 980) {
                let cardContainer = document.getElementById("cardContainer")
                cardContainer.style.border = " 1px solid #ccc"
                cardContainer.style.boxShadow = "0 0 1rem #aaa"
                let questionBox = document.getElementsByClassName("questionBox")
                for (let i = 0; i < questionBox.length; i++) {
                    questionBox[i].onmouseover = function () {
                        this.style.backgroundColor = "#efefef";
                    };
                    questionBox[i].onmouseout = function () {
                        this.style.backgroundColor = "";
                    };
                }
            }
        },
        name: "commonSurvey"
    }
</script>

<style scope>
    body {
        margin: 0;
        padding: 0;
    }
    .block{
        padding: 30px 0;
        text-align: center;
        box-sizing: border-box;
    }
    .el-radio__label {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 18px;
        vertical-align: middle; 
        display: inline-block;
    }
@media screen and (max-width: 768px) {
    .header{
        font-size: 18px;
    }
    .el-dropdown-selfdefine{
        font-size: 14px;
    }
    .formBox{
        padding: 0px 0px 5px;
    }
}
@media screen and (min-width: 768px) {
   .header{
        font-size: 26px;
    }
    .el-dropdown-selfdefine{
        font-size: 16px;
    }
    .formBox{
        padding: 25px 25px 15px;
    }
}
</style>
