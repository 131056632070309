<template>
    <el-dialog :title=title :visible.sync="dialogFormVisible2" :show-close="false" :close-on-click-modal="false" :append-to-body=Nested class="dialog" width="100%">
        <el-form  :model="patientCase" :label-width="formLabelWidth" style="width: 80%; align-items:center; margin:auto">
            <el-form-item label="姓名" :label-width="formLabelWidth">
                <el-input v-model="patientCase.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="性别" :label-width="formLabelWidth">
                <el-radio-group v-model="patientCase.sex" style="float:left; margin-left:1rem; margin-top: 13px" >
                    <el-radio label="男" disabled></el-radio>
                    <el-radio label="女" disabled></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄" :label-width="formLabelWidth">
                <el-input v-model.number="patientCase.age" disabled></el-input>
            </el-form-item>
            <el-form-item label="手机号" :label-width="formLabelWidth">
                <el-input  v-model="patientCase.phone" disabled></el-input>
            </el-form-item>
            <el-form-item label="诊断说明">
                <el-input type="textarea" autosize v-model="patientCase.diagnosis" disabled></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="width: 90%">
            <el-button type="primary" v-on:click="$emit('Invisible')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props:{
        patientCase: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        dialogFormVisible2: {
            type: Boolean,
            default: false
        },
        Nested: {
            type: Boolean,
            default: false
        }
    },
    data() {
            return {
                labelPosition: 'left',
                formLabelWidth: '80px',
            }
        },
    }
</script>

<style>
@media screen and (max-width: 641px) {
    .dialog{
        width: 100%;
    }
    .addDialog{
        width: 100%;
    }
}

@media screen and (min-width: 767px) {
    .dialog{
        width: 80%;
    }
    .addDialog{
        width: 100%;
    }
}

@media screen and (min-width: 1025px) {
    .dialog{
        width: 30%;
    }
    .addDialog{
        width: 50%;
    }
}
</style>