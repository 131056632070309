<template>
    <div>
        <el-main :style="mainStyle" style="background-color: #efefef; padding: 0; margin: 0;">
            <el-header height="4rem" style="background-color: #dca571; position: fixed; width: 100%; z-index: 3;">
                <div style="height:1rem;"></div>
                <span style="display: inline; color: #fff; font-size: 26px; margin:auto">个人中心</span>
            </el-header>
            <el-row>
                <el-col :xs="{span:22, offset:1}" :sm="{span:16, offset:4}" :md="{span:12, offset:6}">
                    <el-row style="line-height: 60px;">
                        <el-col :xs="24" :sm="18" :md="10" style="font-size: 30px; color: rgba(0,0,0,0.98); margin-top: 8rem; text-align: left;">
                            <router-link :to="'/questionnaire'" class="el-icon-back" style="text-decoration:none; color: #000000">量表评定</router-link>
                        </el-col>
                    </el-row>
                    <el-card style="margin-top: 0.3rem; margin-left: auto; margin-right: auto">
                        <el-tabs style="margin:auto;width: 98%; " @tab-click="handleClick">
                            <el-tab-pane label="基本信息">
                                <el-form :label-position="labelPosition" label-width="80px" :model="patient" ref="patient" :rules="rules1" style="text-align:left; width:50%; margin-top: 3rem; margin-left: auto; margin-right: auto">
                                    <el-form-item label="用户名" prop="username">
                                        <el-input v-model="patient.username"></el-input>
                                    </el-form-item>
                                    <el-form-item label="姓名" prop="name">
                                        <el-input v-model="patient.name"></el-input>
                                    </el-form-item>
                                    <el-form-item label="性别" label-width="80px">
                                        <el-radio-group v-model="patient.sex" style="float:left; margin-left:1rem; margin-top: 13px">
                                            <el-radio label="男"></el-radio>
                                            <el-radio label="女"></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="年龄"  prop="age">
                                        <el-input v-model.number="patient.age"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机号">
                                        <el-row>
                                            <el-col :xs="19" :sm="17" :md="21">
                                                <el-input v-model="patient.phone" disabled></el-input>
                                            </el-col>
                                            <el-col :xs="{span:3, offset:1}" :sm="{span:4, offset:1}" :md="{span:2, offset:1}">
                                                <el-button type="primary"  @click="dialogFormVisible = true" icon="el-icon-edit" circle></el-button>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                    <el-form-item label="诊断说明">
                                        <el-input type="textarea" autosize v-model="patient.diagnosis" disabled></el-input>
                                    </el-form-item>
                                </el-form>
                                <el-row style="width:50%; line-height: 20px; margin-top:1rem; margin-bottom:3rem; margin-left: auto; margin-right: auto;">
                                    <el-col style="color: rgba(0,0,0,0.98)" :span="12">
                                        <el-button type="primary" @click="submitForm('patient')" style="float: left;">保存</el-button>
                                    </el-col>
                                    <el-col style="color: rgba(0,0,0,0.98);" :span="12">
                                        <el-button @click="jump" style="float: right;">返回</el-button>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="临床资料" >
                                <el-table
                                    border
                                    stripe
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                                    :data="pdfData"
                                    style="margin: 4rem auto 7rem; align-items: center"
                                    >
                                    <el-table-column prop="ID" label="报告编号" min-width="30"></el-table-column>
                                    <el-table-column prop="QuestionnaireTitle" label="量表名称" min-width="80"></el-table-column>
                                    <el-table-column prop="CreatedAt" label="评定日期" min-width="60"></el-table-column>
                                    <el-table-column label="操作" align="right" min-width="45">
                                        <template slot-scope="scope">
                                            <el-button
                                                @click.native.prevent="downloadpdf(scope.row)"
                                                type="text"
                                                size="small"
                                                :disabled="buttonDisable[scope.$index]"
                                                >
                                                查看
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                    style="margin:auto"
                                    class="smallPagination"
                                    small
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="pageSizes"
                                    :page-size="pageSize"
                                    layout="total, prev, pager, next"
                                    :total="totalCount">
                                </el-pagination>
                                <el-pagination
                                    style="margin:auto"
                                    class="Pagination"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="pageSizes"
                                    :page-size="pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="totalCount">
                                </el-pagination>
                            </el-tab-pane>
                        </el-tabs>
                        <el-dialog title="修改手机号" :visible.sync="dialogFormVisible" style="text-align: left; margin:auto" width="100%" class="dialog">
                            <el-form :model="newPatient" :label-position="labelPosition" ref="newPatient" :rules="rules" style="width: 80%; margin: auto">
                                <el-form-item label="手机号" label-width="80px" prop="phone">
                                    <el-input v-model="newPatient.phone" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-form>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="dialogFormVisible = false">取 消</el-button>
                                <el-button type="primary" @click="submitPhone('newPatient')">确 定</el-button>
                            </div>
                        </el-dialog>
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            var vPhone = (rule,value,callback) => {
                let reg = /^1(3|4|5|6|7|8|9)\d{9}$/
                if (!value){
                    callback(new Error('手机号不能为空'));
                } else if (!reg.test(value)){
                    callback(new Error('手机号格式错误'));
                } else if (value == this.rawData.phone){
                    callback(new Error('手机号未更改'))
                } else {
                    axios.post(window.serverURL + 'uniqueJudge', {
                        phone: value,
                        role: "1"
                    }).then(function(res){
                        if (res.data.data.PhoneUnique != "1"){
                            callback(new Error('此手机号已注册，请输入其他手机号'));
                        }else {
                            callback()
                        }
                    })
                }
            }
            var vUsername = (rule,value,callback) => {
                this.UserNameUnique = true
                if (value != this.rawData.username){
                    if (!value){
                        callback(new Error('用户名不能为空'));
                    } else if (value.length < 2 || value.length > 10 ){
                        callback(new Error('长度在 2 到 10 个字符'));
                    } else {
                        axios.post(window.serverURL + 'uniqueJudge', {
                            username: value,
                        }).then(function(res){
                            if (res.data.data.UserNameUnique != true){
                                callback(new Error('此用户名已被注册，请输入其他用户名'));
                            }else {
                                callback()
                            }
                        })
                    }
                } else {
                    callback()
                }
            }
            return {
                buttonDisable:[],
                pageSize: 5,
                pageSizes: [5,10,15,20],
                currentPage: 1,
                totalCount: 0,
                tabPosition: 'right',
                pdfData:[],
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                labelPosition: 'top',
                loading: false,
                rawData: {},//从数据库获得的原始数据
                diffData: {},
                newPatient: {
                    phone: "",
                },
                patient: {
                    username: '',
                    name: '',
                    phone: '',
                    age: '',
                },
                dialogFormVisible: false,
                rules: {
                    phone: [
                        { validator: vPhone, required: true, trigger: 'blur' }
                    ],
                },
                rules1: {
                    username: [
                        { validator: vUsername, required: true, trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    age: [
                        { type: 'number', message: '年龄必须为数字值'}
                    ]
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            handleSizeChange(val) {//每次切换页面大小时，此方法响应，改变页面大小
                this.pageSize = val;
                this.currentPage = 1;//改变页面大小后，跳转回第一页
                this.searchPDFs()
            },
            handleCurrentChange(val) {//每次切换当前页时，此方法响应，改变当前页
                this.currentPage = val;
                this.searchPDFs()
            },
            handleClick(tab) {
                if(tab.label == "临床资料"){
                    this.searchPDFs()
                }
            },
            async searchPDFs() {
                this.buttonDisable = []
                try {
                    let res = await axios.get(window.serverURL + "searchPDFs/patient",{
                        params: {
                            page: this.currentPage,
                            pageSize: this.pageSize,
                        }
                    })
                    this.pdfData = res.data.data.answerlists
                    this.totalCount = res.data.data.totalPage
                    for (let i = 0; i < this.pdfData.length; i++) {
                        var disable = true
                        this.pdfData[i].CreatedAt = this.pdfData[i].CreatedAt.substring(0, 10).replace("T", " ")
                        if (this.pdfData[i].QuestionnaireType == "CTQ" || this.pdfData[i].QuestionnaireType == "CERQ" || this.pdfData[i].QuestionnaireType == "CAQ" || this.pdfData[i].QuestionnaireType == "LES" || this.pdfData[i].QuestionnaireType == "PHQ-9" || this.pdfData[i].QuestionnaireType == "PSQI" || this.pdfData[i].QuestionnaireType == "SDS" || this.pdfData[i].QuestionnaireType == "SAS&SDS" || this.pdfData[i].QuestionnaireType == "PANAS" || this.pdfData[i].QuestionnaireType == "SCL-90" || this.pdfData[i].QuestionnaireType == "DARS" || this.pdfData[i].QuestionnaireType == "HAMD" || this.pdfData[i].QuestionnaireType == "PDQ-D" || this.pdfData[i].QuestionnaireType == "MHQ" || this.pdfData[i].QuestionnaireType == "Rutter" ){
                            disable = false
                        }
                        this.buttonDisable.push(disable)
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '获取报告列表失败，请刷新重试。',
                        type: 'warning'
                    })
                }
            },
            async downloadpdf(row) {//根据报告id来查询
                try {
                    let res = await axios.get(window.serverURL + 'downloadPDF/patient/' + row.ID)
                    if (res.data.data.TableData) {
                        var TableHeader = []
                        for (let i = 0; i < res.data.data.TableData[0].length; i++) {
                            var header = {}
                            header.prop = "prop"+ i 
                            header.propName = res.data.data.TableData[0][i]
                            TableHeader.push(header)
                        }   
                        var pdfTable = []
                        for (let i = 1; i < res.data.data.TableData.length; i++) {//从TableData数组的第二个元素开始，是表格内的数据
                            var propObject = {}
                            for (let j = 0; j < res.data.data.TableData[0].length; j++) {
                                this.$set(propObject, "prop"+ j, res.data.data.TableData[i][j])
                            }
                            pdfTable.push(propObject) 
                        }
                        res.data.data.TableData = pdfTable
                        this.$set(res.data.data, "TableHeader", TableHeader)
                    }
                    var value = Object.values(res.data.data)
                    var key = Object.keys(res.data.data)
                    for (let i = 0; i < key.length; i++) {
                        this.$set(this.$store.state.pdfdata, key[i], value[i])
                    }
                    localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                    this.$router.push({path:'/report/' + row.QuestionnaireType})
                } catch(e) {
                    this.loading = false
                    this.$message({
                        message: '获取分析报告失败，请刷新重试。',
                        type: 'warning'
                    })
                }
            },
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            jump() {
                this.$router.push({path:'/questionnaire'})
            },
            async updateUser(){
                let ifChange = false
                for (var k in this.rawData) {
                    if (this.rawData.hasOwnProperty(k)&& this.patient.hasOwnProperty(k)) {
                        if (this.rawData[k] !== this.patient[k]) {
                            if (!this.diffData) {
                                this.diffData = {};
                                ifChange = false
                            }
                            this.diffData[k] = this.patient[k];
                            ifChange = true
                        }
                    }
                }
                if (ifChange == true){
                    try {
                        await axios.put(window.serverURL + 'account/patient', {
                            Name: this.diffData.name,
                            Sex: this.diffData.sex,
                            Age: this.diffData.age,
                            Username: this.diffData.username
                        })
                        this.$message({
                            message: '个人信息修改成功！',
                            type: 'success'
                        })
                        this.loading = false
                        this.getUser()
                    } catch (e) {
                        this.loading = false
                        this.$message({
                            message: '个人信息修改失败，请再次尝试提交。',
                            type: 'error'
                        })
                    }
                } 
            },
            submitPhone(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios.put(window.serverURL + 'account/patient',{
                        Phone: this.newPatient.phone, 
                    }).then(() => {
                        this.$message({
                            message:'手机号修改成功！',
                            type:'success'
                        })
                        this.getUser()
                        this.newPatient.phone = ''
                        this.dialogFormVisible = false
                    }).catch(()=>{
                        this.$message({
                            message:'手机号修改失败，请再次尝试提交。',
                            type:'error'
                        })
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.updateUser()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async getUser(){
                try {
                    let res = await axios.get(window.serverURL + 'account/patient')
                    if (res.data.length !== 0) {
                        this.rawData = { ...res.data.data}
                        this.patient = res.data.data
                        this.patient.age = Number(this.patient.age)
                        this.$addStorageEvent("username", this.patient.username);
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '个人信息获取失败，请刷新重试。',
                        type: 'error'
                    })
                }
            },
        },
        created() {
            if (this.$route.path !== '/login'|| this.$route.path !== '/register') {
                this.loading = true
                axios.get(window.serverURL + 'verify/patient')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.getUser()
        }
    }
</script>

<style>
@media screen and (max-width: 769px) {
    .Pagination {
        display: none;
    }
}
@media screen and (min-width: 769px) {
    .smallPagination {
        display: none;
    }
}
</style>