<template>
    <div>
        <el-main :style="mainStyle" style="padding: 0; margin: 0; background-color: #efefef; color: rgba(0,0,0,0.98);">
            <div>
                <div style="font-size: 25px; float: left;margin-left: 2rem; margin-top: 6rem">
                    {{"病例列表"}}
                </div>
            </div>
            <div>
                <el-card style="width: 98%; align-items: center; margin-top: 9rem; margin-left: auto; margin-right: auto">
                    <el-row style="width: 98%; margin-left: auto; margin-right: auto;text-align:left;">
                        <el-col :xs="24" :sm="6" style="color: rgba(0,0,0,0.98); font-size: 30px; margin-top: 0.1rem; text-align: left;">
                            <el-button  type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">添加病例</el-button>
                        </el-col>
                        <el-col :xs="24" :sm="18" style="color: rgba(0,0,0,0.98); font-size: 30px; margin-top: 0.1rem;">
                            <div class="searchBox">
                                <el-input placeholder="请输入内容"  v-model="keyword" class="input-with-select" clearable>
                                    <el-select v-model="keywordProperty" slot="prepend" placeholder="请选择" style="width: 117px">
                                        <el-option label="姓名" value="name"></el-option>
                                        <el-option label="手机号" value="phone"></el-option>
                                    </el-select>
                                    <el-button slot="append" icon="el-icon-search" @click="searchPatients"> </el-button>
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <el-dialog title="添加病例" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :destroy-on-close="true" width="100%"  style="margin:auto" class="addDialog">
                        <template>
                            <el-form :model="patient" :rules="rules" ref="patient">
                                <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
                                    <el-input v-model="patient.name" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="性别" label-width="80px" prop="sex">
                                    <el-radio-group v-model="patient.sex" style="float:left; margin-left:2rem;margin-top: 13px">
                                        <el-radio label="男"></el-radio>
                                        <el-radio label="女"></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="年龄" :label-width="formLabelWidth" prop="age">
                                    <el-input v-model.number="patient.age" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                                    <el-input v-model="patient.phone" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="诊断说明" :label-width="formLabelWidth" prop="diagnosis">
                                    <el-input v-model="patient.diagnosis" autocomplete="off" @keyup.native.enter="submitForm('patient')"></el-input>
                                </el-form-item>
                            </el-form>
                        </template>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="dialogFormVisible = false">取 消</el-button>
                            <el-button type="primary" @click="submitForm('patient')">确 定</el-button>
                        </div>
                        <!-- <mydialog style="margin:auto" :patientCase="patientCase" :title="title" :dialogFormVisible="dialogFormVisible2" v-on:Invisible="dialogFormVisible2 = false" :Nested=true /> -->
                    </el-dialog>
                    <el-table :data="patientList"
                        v-loading="tableLoading"
                        border
                        stripe
                        style="width: 98%; margin: 4rem auto 7rem; align-items: center"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        @sort-change='tableSortChange'>
                        <el-table-column prop="id" label="病例编号" sortable="custom" min-width="45"></el-table-column>
                        <el-table-column prop="name" label="姓名" min-width="63"></el-table-column>
                        <el-table-column prop="sex" label="性别" min-width="35"></el-table-column>
                        <el-table-column prop="age" label="年龄" sortable="custom" min-width="45"></el-table-column>
                        <el-table-column prop="phone" label="手机号" min-width="71"></el-table-column>
                        <el-table-column label="操作" align="right" min-width="47">
                            <template slot-scope="scope">
                                <el-button
                                    @click.native.prevent="jump(scope.row)"
                                    type="text"
                                    size="small">
                                        详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        style="margin:auto"
                        class="smallPagination"
                        small
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        layout="total, prev, pager, next"
                        :total="totalCount">
                    </el-pagination>
                    <el-pagination
                        style="margin:auto"
                        class="Pagination"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount">
                    </el-pagination>
                    <mydialog style="margin:auto" :patientCase="patientCase" :title="title" :dialogFormVisible2="dialogFormVisible2"  v-on:Invisible="dialogFormVisible2 = false" :Nested=false />
                </el-card>
            </div>
        </el-main>
    </div>
</template>

<script>
    import axios from 'axios'
    import mydialog from './mydialog.vue'
    export default {
        components: { mydialog },
        data() {
            var vPhone = (rule,value,callback) => {
                let reg = /^1(3|4|5|6|7|8|9)\d{9}$/
                this.PhoneUnique = true
                if (!value){
                    callback(new Error('手机号不能为空'));
                } else if (!reg.test(value)){
                    callback(new Error('手机号格式错误'));
                } else {
                    axios.post(window.serverURL + 'uniqueJudge', {
                        phone: value,
                        role: "1"
                    }).then((res)=>{
                        if (res.data.data.PhoneUnique != "1"){
                            this.PhoneUnique = false
                            callback(new Error('此手机号已注册，请输入其他手机号'));
                        }else {
                            callback()
                        }
                    })
                }
            }
            return {
                title: '',
                labelPosition: "top",
                PhoneUnique: true,
                tableLoading: false,
                patientList: [],
                dialogFormVisible: false,
                dialogFormVisible2: false,
                formLabelWidth: '80px',
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                pageSize: 10,
                pageSizes: [5,10,15,20],
                currentPage: 1,
                totalCount: 0,
                orderProperty: 'id',
                order: "",
                keyword:'',
                keywordProperty: "",
                name: '',
                phone: '',
                patient: {
                    name: '',
                    phone: '',
                    sex: '',
                    age: '',
                    diagnosis: '',
                },
                patientCase: {},
                rules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    age: [
                        { required: true, type: 'number', message: '年龄必须为数字值'}
                    ],
                    phone: [
                        { validator: vPhone, required: true, trigger: 'blur' }
                    ],
                    diagnosis: [
                        { required: true, message: '请填写诊断说明', trigger: 'blur' }
                    ]
                },
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            PhoneUnique: function () {
                if (this.PhoneUnique == false) {
                    this.searchPatientCase(this.patient.phone)
                    this.title = "该手机号已被使用，详情如下"
                }
            }
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            handleSizeChange(val) {//每次切换页面大小时，此方法响应，改变页面大小
              this.pageSize = val;
              this.currentPage = 1;//改变页面大小后，跳转回第一页
              this.searchRequest()
            },
            handleCurrentChange(val) {//每次切换当前页时，此方法响应，改变当前页
              this.currentPage = val;
              this.searchRequest()
            },
            tableSortChange(column) {
                this.orderProperty = column.prop
                if (column.order === "descending") {
                    this.order = "descending"
                } else {
                    this.order = ""
                }
                this.searchRequest()
            },
            async searchPatientCase(phone) {
                this.dialogFormVisible2 = true
                this.title = "病例详情"
                try {
                    let res = await axios.get(window.serverURL + "uniqueJudge", {
                    params: {
                        phone:phone,
                    }
                    })
                    this.patientCase = res.data.data
                } catch (err) {
                    this.$message({
                        message: '获取病例详情失败，请刷新重试。',
                        type: 'warning'
                    })
                }
            },
            searchPatients() {//条件查找项目
                let keyword = this.keyword
                this.currentPage = 1
                if (this.keywordProperty === "name") {
                    this.name = keyword
                    this.phone = ""
                } else if (this.keywordProperty === "phone") {
                    this.phone = keyword
                    this.name = ""
                }
                this.searchRequest()
                if (this.keywordProperty === "name") {
                    this.$router.push({path:'/home/patient/', query:{name: this.keyword}})
                } else if (this.keywordProperty === "phone") {
                    this.$router.push({path:'/home/patient/', query:{phone: this.keyword}})
                }
                this.keyword = ""
            },
            async searchRequest() {
                try {
                    let res = await axios.get(window.serverURL + "patient", {
                        params: {
                            page: this.currentPage,
                            pageSize: this.pageSize,
                            descend: this.order,
                            orderProperty: this.orderProperty,
                            name: this.name,
                            phone: this.phone,
                        }
                    })
                    this.patientList = res.data.data.patients
                    this.totalCount = res.data.data.totalPage
                } catch (e) {
                    this.tableLoading = false
                    this.$message({
                        message: '获取病例信息失败或无病例，请刷新重试或添加新病例。',
                        type: 'error'
                    });
                }
            },
            jump(row) {
                this.$router.push('/home/patient/' + row.id)
            },
            async addPatient() {
                try {
                    let res = await axios.post(window.serverURL + 'patient', {
                    Name: this.patient.name,
                    Sex: this.patient.sex,
                    Age: this.patient.age,
                    Phone: this.patient.phone,
                    Diagnosis: this.patient.diagnosis
                    })
                    if (res.status === 200) {
                        this.loading = false
                        this.$message({
                            message: '病例添加成功。',
                            type: 'success'
                        })
                        this.patient = {}
                    }
                    this.searchRequest()
                } catch(err) {
                    if (err.response.status === 400) {
                        this.$store.commit('needLog')
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$message({
                        message: '病例添加失败。',
                        type: 'error'
                    })
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.addPatient()
                        this.dialogFormVisible = false
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
        created() {
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify/doctor')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            let path = this.$route.fullPath
            let questionmark = path.indexOf("?")
            let equalsign = path.indexOf("=")
            let keywordProperty = path.substring(questionmark + 1, equalsign)
            let keyword = decodeURIComponent(path.substring(equalsign + 1))
            if (keywordProperty === "name") {
                this.name = keyword
                this.phone = ""
            } else if (keywordProperty === "phone") {
                this.phone = keyword
                this.name = ""
            } else {
                this.phone = ""
                this.name = ""
            }
            this.keyword = ""
            this.searchRequest()
            this.keywordProperty = "name"
        }
        
    }
</script>

<style scoped>
@media screen and (max-width: 641px) {
    .input-with-select{
        width: 300px;
    }
    .searchBox{
        text-align: left; 
    }
}
@media screen and (min-width: 641px) {
    .input-with-select{
        width: 400px;
    }
    .searchBox{
        text-align: right; 
    }
}
</style>