<template>
    <div>
        <el-main :style="mainStyle" style="padding: 0; margin: 0; background-color: #efefef; color: rgba(0,0,0,0.98);">
            <div>
                <div style="font-size: 25px; float: left; margin-left: 2rem; margin-top: 6rem">
                    {{"项目列表"}}
                </div>
            </div>
            <div>
                <el-card style="width: 98%; margin-top: 9rem; margin-left: auto; margin-right: auto">
                    <el-row style="width: 98%; margin-left: auto; margin-right: auto">
                        <el-col :xs="24" :sm="6" style="color: rgba(0,0,0,0.98); font-size: 30px; margin-top: 0.1rem; text-align: left;">
                            <el-button type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">添加项目</el-button>
                        </el-col>
                        <el-col :xs="24" :sm="18" style="color: rgba(0,0,0,0.98); font-size: 30px; margin-top: 0.1rem;">
                            <div class="searchBox">
                                <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select" clearable>
                                    <el-select v-model="keywordProperty" slot="prepend" placeholder="请选择" style="width: 117px">
                                        <el-option label="项目名称" value="name"></el-option>
                                        <el-option label="项目参与者" value="participant"></el-option>
                                    </el-select>
                                    <el-button slot="append" icon="el-icon-search" @click="searchProjects"> </el-button>
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <el-dialog title="添加项目" :visible.sync="dialogFormVisible" width="100%" style="margin:auto" class="addDialog">
                        <template>
                            <el-form :model="project">
                                <el-form-item label="项目名称" :label-width="formLabelWidth">
                                    <el-input v-model="project.name" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="项目参与者" :label-width="formLabelWidth">
                                    <el-input v-model="project.participant" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="开始时间" :label-width="formLabelWidth">
                                    <el-date-picker
                                        v-model="project.BeginTime"
                                        type="date"
                                        placeholder="选择日期"
                                        style="width:100%">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="截止时间" :label-width="formLabelWidth">
                                    <el-date-picker
                                        v-model="project.EndTime"
                                        type="date"
                                        placeholder="选择日期"
                                        style="width:100%">
                                    </el-date-picker>
                                </el-form-item>
                            </el-form>
                        </template>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="dialogFormVisible = false">取 消</el-button>
                            <el-button type="primary" @click="addProject">确 定</el-button>
                        </div>
                    </el-dialog>
                    <el-table :data="projectList"
                        v-loading="tableLoading"
                        border
                        stripe
                        style="width: 98%; margin: 4rem auto 7rem; align-items: center"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        @sort-change='tableSortChange'
                        :default-sort = "{prop: ['id','state','created_at'], order: ['descending','ascending','ascending']}">
                        <el-table-column prop="id" label="项目编号" sortable="custom" min-width="45"></el-table-column>
                        <el-table-column prop="name" label="项目名称" min-width="80"></el-table-column>
                        <el-table-column prop="participant" label="参与者" min-width="65"></el-table-column>
                        <el-table-column prop="state" label="项目状态" sortable="custom" min-width="60"></el-table-column>
                        <el-table-column prop="begin_time" label="开始时间" sortable="custom" min-width="60"></el-table-column>
                        <el-table-column label="操作" align="right" min-width="45">
                            <template slot-scope="scope">
                                <el-row>
                                    <el-col :xs="24" :sm="8">
                                        <el-button
                                            @click.native.prevent="jump(scope.row)"
                                            type="text"
                                            size="small">
                                                详情
                                        </el-button>
                                    </el-col>
                                    <el-col :xs="24" :sm="{span:8, offset:8}">
                                        <el-button
                                            @click.native.prevent="deleteRow(scope.$index, projectList)"
                                            type="text"
                                            size="small">
                                            移除
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        style="margin:auto"
                        class="smallPagination"
                        small
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        layout="total, prev, pager, next"
                        :total="totalCount">
                    </el-pagination>
                    <el-pagination
                        style="margin:auto"
                        class="Pagination"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount">
                    </el-pagination>
                </el-card>
            </div>
        </el-main>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data(){
            return{
                keyword:'',
                keywordProperty:"",
                tableLoading: false,
                projectList:[],
                project:{},
                dialogFormVisible: false,
                formLabelWidth: '120px',
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                pageSize: 10,
                pageSizes:[5,10,15,20],
                currentPage: 1,
                totalCount: 0,//projectlist数组的长度，记录数
                orderProperty: 'id',
                order: "",
                name:"",
                participant:"",
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            handleSizeChange(val) {//每次切换页面大小时，此方法响应，改变页面大小
              this.pageSize = val;
              this.currentPage = 1;//改变页面大小后，跳转回第一页
              this.searchRequest()
            },
            handleCurrentChange(val) {//每次切换当前页时，此方法响应，改变当前页
              this.currentPage = val;
              this.searchRequest()
            },
            async addProject() {
                this.dialogFormVisible = false
                if (this.project.name === '' || this.project.participant === '' || this.project.BeginTime == "" || this.project.EndTime == "") {
                    this.$message({
                        message: '请填写所有信息',
                        type: 'warning'
                    });
                    return
                }
                this.loading = true
                try {
                   let res = await axios.post(window.serverURL + 'project', {
                       Name: this.project.name,
                       Participant: this.project.participant,
                       BeginTime: this.project.BeginTime,
                       EndTime: this.project.EndTime
                   })
                    if (res.status === 200) {
                        this.showHead = !this.showHead
                        this.loading = false
                        this.$message({
                            message: '项目添加成功。',
                            type: 'success'
                        })
                        this.project = {}
                    }
                    this.searchRequest()
                } catch (err) {
                    if (err.response.status === 400) {
                        this.$store.commit('needLog')
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$message({
                        message: '项目添加失败。',
                        type: 'error'
                    })
                }
            },
            async deleteRow(index, rows) {
                this.tableLoading = true
                try {
                    await axios.delete(window.serverURL + "project/" + this.projectList[index].id)
                    this.tableLoading = false
                    this.searchRequest()
                    this.$message({
                        message: '项目删除成功。',
                        type: 'success'
                    })
                } catch (e) {
                    this.tableLoading = false
                    this.$message({
                        message: '项目删除失败。',
                        type: 'error'
                    });
                }
                rows.splice(index, 1);
            },
            tableSortChange(column) {
                if (column.prop === "state") {
                    this.orderProperty = "end_time"
                } else {
                    this.orderProperty = column.prop
                }
                if (column.order === "descending") {
                    this.order = "descending"
                } else {
                    this.order = ""
                }
                this.searchRequest()
            },
            searchProjects() {//条件查找项目
                let keyword = this.keyword
                this.currentPage = 1
                if (this.keywordProperty === "name") {
                    this.name = keyword
                    this.participant = ""
                } else if (this.keywordProperty === "participant") {
                    this.participant = keyword
                    this.name = ""
                }
                this.searchRequest()
                if (this.keywordProperty === "name") {
                    this.$router.push({path:'/home/project/', query:{name: this.keyword}})
                } else if (this.keywordProperty === "participant") {
                    this.$router.push({path:'/home/project/', query:{participant: this.keyword}})
                }
                this.keyword = ""
            },
            jump(row) {
                this.$router.push('/home/project/' + row.id)
            },
            async searchRequest() {
                try {
                    let res = await axios.get(window.serverURL + "account/doctor/project", {
                        params: {
                            page: this.currentPage,
                            pageSize: this.pageSize,
                            descend: this.order,
                            orderProperty: this.orderProperty,
                            name: this.name,
                            participant: this.participant,
                        }
                    })
                    this.projectList = res.data.data.projects
                    this.totalCount = res.data.data.totalPage
                    for (let i = 0; i < this.projectList.length; i ++) {
                        this.projectList[i].begin_time = this.projectList[i].begintime.substring(0, 10).replace("T", " ")
                        if (Date.parse(this.projectList[i].endtime) > new Date()) {
                            this.projectList[i].state = "进行中"
                        } else {
                            this.projectList[i].state = "已完结"
                        }
                    }
                } catch (e) {
                    this.tableLoading = false
                    this.$message({
                        message: '获取项目信息失败或无项目，请刷新重试或添加新项目。',
                        type: 'error'
                    });
                }
            }
        },
        created() {
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify/doctor')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            let path = this.$route.fullPath
            let questionmark = path.indexOf("?")
            let equalsign = path.indexOf("=")
            let keywordProperty = path.substring(questionmark + 1, equalsign)
            let keyword = decodeURIComponent(path.substring(equalsign + 1))
            if (keywordProperty === "name") {
                this.name = keyword
                this.participant = ""
            } else if (keywordProperty === "participant") {
                this.participant = keyword
                this.name = ""
            } else {
                this.participant = ""
                this.name = ""
            }
            this.keyword = ""
            this.searchRequest()
            this.keywordProperty = "name"
        }
    }
</script>

<style scoped>
@media screen and (max-width: 641px) {
    .input-with-select{
        width: 300px;
    }
    .searchBox{
        text-align: left; 
    }
}
@media screen and (min-width: 641px) {
    .input-with-select{
        width: 400px;
    }
    .searchBox{
        text-align: right; 
    }
}
</style>
