<template>
    <div>
        <el-main :style="mainStyle" style="padding: 0; margin: 0; background-color: #efefef; color: rgba(0,0,0,0.98);">
            <el-row style="margin-top: 6rem; ">
                <el-col :xs="{span:10, offset:1}" :sm="8" style="text-align:left; ">
                    <div style="font-size: 25px;" class="projectTitle">
                        {{"项目详情"}}
                    </div>
                </el-col>
                <el-col :xs="{span:12, offset:1}" :sm="{span:10, offset:5}" style="text-align:right;">
                    <el-breadcrumb separator="/" style="font-size:18px; float:right; margin-top:0.5rem;">
                        <el-breadcrumb-item :to="{ path: '/home/project' }" icon="el-icon-s-management">项目列表</el-breadcrumb-item>
                        <el-breadcrumb-item>{{project.name}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-col>
            </el-row>
            <div style="margin-top: 1rem">
                <el-tabs type="border-card" style="width: 98%; align-items: center; margin-left: auto; margin-right: auto" >
                    <el-tab-pane label="基本信息">
                        <el-row>
                            <el-col :xs="24" :sm="{span:12, offset:6}" :md="{span:8, offset:8}">
                                <div style="align-items: center; margin: 4rem auto 7rem; background-color: #fff; top:0rem">
                                    <el-form :label-position="labelPosition" label-width="80px" :model="project">
                                        <el-form-item label="项目名称">
                                            <el-input v-model="project.name" :disabled="stateJudge"></el-input>
                                        </el-form-item>
                                        <el-form-item label="参与者">
                                            <el-input v-model="project.participant" :disabled="stateJudge"></el-input>
                                        </el-form-item>
                                        <el-form-item label="开始时间">
                                            <el-date-picker type="date" placeholder="选择日期" v-model="project.begintime" :picker-options="startPickerOptions" style="width: 100%"></el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="截止时间">
                                            <el-date-picker type="date" placeholder="选择日期" v-model="project.endtime" :picker-options="endPickerOptions" style="width: 100%"></el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="项目状态">
                                            <el-input v-model="project.state" :disabled="true"></el-input>
                                        </el-form-item>
                                    </el-form>
                                    <el-button type="primary" @click="updateProject">保存</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="量表管理">
                        <el-row>
                            <el-col :span="2" style="color: rgba(0,0,0,0.98); font-size: 30px; margin-top: 0.1rem">
                                <el-button @click="searchAllScales" v-show="!stateJudge" icon="el-icon-plus" type="primary">管理关联量表</el-button>
                            </el-col>
                        </el-row>
                        <el-dialog title="管理关联量表" :visible.sync="dialogFormVisible" width="100%" style="margin:auto" class="manageScaleDialog">
                            <el-transfer
                                :titles="['未选择量表', '已选择量表']"
                                v-model="chosenKey"
                                :data="unchosenScale"
                                >
                            </el-transfer>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="dialogFormVisible = false">取 消</el-button>
                                <el-button type="primary" @click="judgeAddOrDel">确 定</el-button>
                            </div>
                        </el-dialog>
                        <el-table :data="scale"
                            border
                            stripe
                            style="margin: 4rem auto 7rem; width: 98%; align-items: center"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            @sort-change='tableSortChange'
                            :default-sort = "{prop: 'key', order: 'descending'}">
                            <el-table-column prop="key" label="量表编号" sortable="custom" width="85"></el-table-column>
                            <el-table-column prop="label" label="量表名称"></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                        @click.native.prevent="deleteAssociateScale(scope.$index)"
                                        type="text"
                                        size="small">
                                        移除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            style="margin:auto"
                            class="smallPagination"
                            small
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="totalCount">
                        </el-pagination>
                        <el-pagination
                            style="margin:auto"
                            class="Pagination"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount">
                        </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="数据导出">
                        <el-row>
                            <el-col :xs="24" :sm="{span:12, offset:6}" :md="{span:8, offset:8}">
                                <div style="align-items: center; margin: 4rem auto 7rem; background-color: #fff; top:0rem">
                                    <el-form ref="condition" :model="condition" label-width="80px" :label-position="labelPosition" >
                                        <el-form-item label="问卷类型">
                                            <el-select v-model="condition.type" placeholder="请选择问卷类型"  style="width:100%">
                                                <el-option v-for="questionnaire in scale"
                                                    :key="questionnaire.type"
                                                    :value="questionnaire.type"
                                                    :label="questionnaire.label"
                                                    >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="病例查询">
                                            <el-input  v-model="condition.userName"  prefix-icon="el-icon-search"
                                                clearable
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item label="时段选择">
                                            <el-date-picker
                                                style="width:100%"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                v-model="condition.BeginTime"
                                                type="date"
                                                placeholder="选择开始日期">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-date-picker
                                                style="width:100%"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                v-model="condition.EndTime"
                                                type="date"
                                                placeholder="选择结束日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-form>
                                    <el-button type="primary" @click="allSearch">查询</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-main>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                order:'',
                stateJudge:false,
                loading: false,
                labelPosition: 'right',
                dialogFormVisible: false,
                unchosenScale: [],
                chosenKey: [],
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                project: {},
                scale: [],
                chosenScale: [],
                rawProject:[],
                rawData: [],
                rawChosen: [],
                activeName:"first",
                condition:{
                    type:'',
                    userName:'',
                    BeginTime:'',
                    EndTime:'',
                },
                ChangeArr:[],
                pageSize: 10,
                pageSizes:[5,10,15,20],
                currentPage: 1,
                totalCount: 0,//projectlist数组的长度，记录数
            }
        },
        computed: {
            startPickerOptions() {
                let that = this;
                return{
                    disabledDate(time) {
                        let endtime = Date.parse(that.project.endtime)
                        return time.getTime() >= endtime;
                    },
                }
            },
            endPickerOptions(){
                let that = this;
                return{
                    disabledDate(time) {
                        let begintime = Date.parse(that.project.begintime)
                        return time.getTime() <= begintime;
                    },
                }
            },
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            handleSizeChange(val) {//每次切换页面大小时，此方法响应，改变页面大小
              this.pageSize = val;
              this.currentPage = 1;//改变页面大小后，跳转回第一页
              this.searchAssociateScale()
            },
            handleCurrentChange(val) {//每次切换当前页时，此方法响应，改变当前页
              this.currentPage = val;
              this.searchAssociateScale()
            },
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            async searchProject() {//根据项目id查找项目详细信息
                try {
                    let res = await axios.get(window.serverURL + "project/" + this.$route.params.id)
                    this.project = res.data.data
                    this.rawProject = { ...res.data.data}
                    if (Date.parse(this.project.endtime) > new Date()) {
                        this.project.state = "进行中"
                        this.stateJudge = false
                    } else {
                        this.project.state = "已完结"
                        this.stateJudge = true
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '获取项目详情失败，请刷新重试。',
                        type: 'warning'
                    })
                }
            },
            tableSortChange(column) {
                if (column.order === "descending") {
                    this.order = "descending"
                } else {
                    this.order = ""
                }
                this.searchAssociateScale()
            },
            async updateProject() {
                for (var k in this.rawProject) {
                    if (this.rawProject.hasOwnProperty(k) && this.project.hasOwnProperty(k)) {
                        if (this.rawProject[k] !== this.project[k]) {
                            if (!this.diffData) {
                                this.diffData = {};
                            }
                            this.diffData[k] = this.project[k];
                        }
                    }
                }
                try {
                    await axios.put(window.serverURL + 'project', {
                        Name: this.diffData.name,
                        Participant: this.diffData.participant,
                        BeginTime: this.diffData.begintime,
                        EndTime: this.diffData.endtime,
                        Id: this.project.id
                    })
                    this.$message({
                        message: '更新项目信息成功。',
                        type: 'success'
                    })
                    this.searchProject()
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '更新项目信息失败，请重新提交。',
                        type: 'error'
                    })
                }
            },
            async changeAssociateScale(ChangeArr, Method) {
                try {
                    await axios.post(window.serverURL + 'project/scale', {
                        ID: ChangeArr,
                        ProjectID: this.project.id,
                        Method: Method,
                    })
                    this.$message({
                        message: '变更关联量表成功。',
                        type: 'success'
                    })
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '变更关联量表失败，请刷新重试。',
                        type: 'error'
                    })
                }
                this.searchAssociateScale()
            },
            deleteAssociateScale(index) {//在table中删除已关联的量表
                let ID = []
                ID.push(this.scale[index].key)
                this.changeAssociateScale(ID, "del")
            },
            async searchAllScales() {//关联量表时需要显示所有的量表
                this.dialogFormVisible = true
                this.unchosenScale = []
                try {
                    let res= await axios.get(window.serverURL + "questionnaire")
                    this.temp = res.data.data
                    this.rawData = { ...res.data.data}//全部的问卷数据
                    for (let i = 0; i < this.temp.length; i++) {
                        var questionnaire = {}
                        questionnaire.id = this.temp[i].id
                        questionnaire.title = this.temp[i].title
                        this.unchosenScale.push({label:questionnaire.title, key:questionnaire.id})
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '获取量表信息失败，请刷新重试。',
                        type: 'error'
                    })
                }
            },
            async searchAssociateScale() {//查找已关联量表
                this.scale = []
                this.rawChosen = []
                try {
                    let res = await axios.get(window.serverURL + "project/scale/" + this.$route.params.id, {
                        params: {
                            descend: this.order,
                            page: this.currentPage,
                            pageSize: this.pageSize,
                        }
                    })
                    this.temp = res.data.data.questionnaires
                    this.totalCount = res.data.data.totalPage
                    this.chosenKey = []
                    for (let i = 0; i < this.temp.length; i++) {
                        var questionnaire = {}
                        questionnaire.id = this.temp[i].ID
                        questionnaire.title = this.temp[i].QuestionnaireTitle
                        questionnaire.type = this.temp[i].QuestionnaireType
                        this.scale.push({label:questionnaire.title, key:questionnaire.id, type:questionnaire.type})
                        this.chosenKey.push(questionnaire.id)
                        this.rawChosen.push(questionnaire.id)//从数据库中获取的已经添加的量表，用于跟新添加或删除操作之后的量表数据进行对比
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '获取已关联量表失败，请刷新重试。',
                        type: 'error'
                    })
                }
            },
            exist(num, arr1) {
                for (var j = 0; j < arr1.length; j++) {
                    if (num === arr1[j]) {
                        return false;
                    }
                }
                return true;
            },
            judgeAddOrDel() {
                this.dialogFormVisible = false
                this.ChangeArr = []
                var longerArr = []
                var shortArr = []
                var Method = ''
                if (this.chosenKey.length <= this.rawChosen.length) {
                    longerArr = this.rawChosen
                    shortArr = this.chosenKey
                    Method = "del"
                } else {
                    longerArr = this.chosenKey
                    shortArr = this.rawChosen
                    Method = "add"
                }
                for (let i = 0; i < longerArr.length; i++) {
                    if (this.exist(longerArr[i], shortArr)) {
                        this.ChangeArr[this.ChangeArr.length] = longerArr[i];
                    }
                }
                this.changeAssociateScale(this.ChangeArr, Method)
            },
            async allSearch() {
                this.loading = true
                if (this.condition.EndTime =="" && this.condition.BeginTime != "") {
                    this.$message({
                        message: '请填写结束日期',
                        type: 'warning'
                    })
                } else if (this.condition.EndTime !="" && this.condition.BeginTime == "") {
                    this.$message({
                        message: '请填写开始日期',
                        type: 'warning'
                    })
                } else {
                    try {
                        let res = await axios.get(window.serverURL + "search", {
                            params: {
                                UserName: this.condition.userName,
                                QuestionnaireType: this.condition.type,
                                BeginTime: this.condition.BeginTime,
                                EndTime: this.condition.EndTime
                            }
                        })
                        const { Parser } = require('json2csv')
                        let FileSaver = require('file-saver');
                        const fields = ['ID', 'Name', 'UserName', 'QuestionnaireType', 'QuestionNumber', 'ChoiceAnswerList', 'TextAnswer', 'TableData', 'Time', 'Date', 'CreatedAt']
                        const json2csvParser = new Parser({ fields })
                        const csv = json2csvParser.parse(res.data.data)
                        let exportContent = "\uFEFF";
                        let blob = new Blob([exportContent + csv], {
                            type: "text/plain;charset=utf-8"
                        });
                        FileSaver.saveAs(blob, "searchdata.csv");
                        this.loading = false
                        this.$message({
                            message: '数据导出成功。',
                            type: 'success'
                        })
                    } catch (e) {
                        this.loading = false
                        this.$message({
                            message: '数据导出失败，请刷新重试。',
                            type: 'error'
                        })
                    }
                }   
            },
        },
        created() {
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify/doctor')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error =>{
                        console.log(error)
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.searchProject()
            this.searchAssociateScale()
        }
    }
</script>

<style scoped>
    .el-transfer >>> .el-transfer-panel{
        width:250px;
        text-align : left;
    }
    @media screen and (min-width: 767px) {
    .projectTitle{
        margin-left:2rem;
    }
    .manageScaleDialog{
        width: 100%;
    }
    }
    @media screen and (min-width: 1367px) {
    .manageScaleDialog{
        width: 50%;
    }
    }
</style>
